import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { FaSearch, FaRegUserCircle, FaAngleDown } from "react-icons/fa";
import { getCookie, setCookie, removeCookie } from "../config/coockies";
import { apiurl, marketplaceapiurl } from "../config/config.js";
import axios from "axios";
import { useUser } from "../contexts/UserProvider.js";
import Notification from "./notifcation";
import { LiaStoreAltSolid } from "react-icons/lia";
import { jwtDecode } from "jwt-decode";
import { IoNotificationsOutline } from "react-icons/io5";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Loader from "../components/loader.js"
import { socket } from "../config/socket.js";
import { toast } from "react-toastify";
import  messageTone from "../components/message_tone1.mp3"
import alertMessage from "../components/sound/message_tone2.mp3";
export default function Header() {
  const { userData } = useUser();
  const [activeLink, setActiveLink] = useState("");
  const [user, setuser] = useState({});
  const [navbar, setNavbar] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [communities, setCommunities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [users, setUsers] = useState([]);
  const [isseller, setIsseller] = useState(false);
  const [getUser, setGetUser] = useState(false);
  const [getprofiledata, setGetProfileData] = useState({});
  const [getProfileImage, setGetProfileImage] = useState("");
  const [getNotifications, setGetNotifications] = useState(null);
  const [searchTab, setSearchTab] = useState(false);
  const [loader, setLoader] = useState(true);

  let token = getCookie("savage_token");
  let hero_img = getCookie("tile");
  let count = getCookie("nt_ctn");
  let decodedToken;

  useEffect(() => {}, []);

  const [showProfilePrompt, setShowProfilePrompt] = useState(false);
  useEffect(() => {
    const promptShown = localStorage.getItem("profilePromptShown");
    setShowProfilePrompt(!promptShown); 
  }, []);

  useEffect(() => {
    const token = getCookie("savage_token");
    const hero_img = getCookie("tile");
    const count = getCookie('nt_ctn');
    if (token) {
      setGetUser(true);
      setGetProfileImage(hero_img);
      setGetNotifications(count);
    }
    if (!token) {
      setGetProfileImage("");
      setGetUser(false);
    }
  }, [getUser,getProfileImage,hero_img,count]);

  if (token) {
    decodedToken = jwtDecode(token);
  }

  const handleSearch = (e) => {
    const {name, value} = e.target;
    if(name === 'searchInput'){
      setLoader(true);
      if(value.length <= 2){
        setSearchData([]);
        setCommunities([]);
        setPosts([]);
        setUsers([]);
      }
      if(value){
        setSearchQuery(value);
        handleUniversalSearch(value);
      } else{
        setSearchQuery("");
        setSearchData([]);
        setCommunities(null);
        setPosts([]);
        setUsers([]);
      }
    }
  }
  
  const handleUniversalSearch = async (searchTerm) => {
    setLoader(true);
    try {
      const response = await axios.get(`${apiurl}/search?by=${searchTerm}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      if(response?.data?.data){
        setSearchData(response.data?.data);
        setCommunities(response.data?.data?.communities || []);
        setPosts(response.data?.data?.posts || []);
        const filteredUsers = (response.data?.data?.users || []).filter(user => user._id !== decodedToken?.user?._id);
        setUsers(filteredUsers || []);
        setLoader(false);
      }
      // console.log('universal search response', response)
    } catch (error) {
      setLoader(false);
      console.error("Universal search error:", error);
    }
  };

  const checkVendor = async () => {
    try {
      const { data } = await axios({
        url: `${marketplaceapiurl}/seller/checksellerexistance`,
        method: "POST",
        data: {
          email: decodedToken.user.email,
        },
      });
      // console.log("role listing", data);

      if (data?.data) {
        if (typeof data?.data === "string") {
          setIsseller(false);
          const isSeller = data?.data;
          // const expirationTime = 50 * 60;
          setCookie("is_seller", isSeller);
        } else {
          if (data?.data?.paid === true || data?.data?.sellerType=="individual" || data?.data?.sellerType ==="organization") {
            setIsseller(true);
            // const isSeller = data?.data;
            // const expirationTime = 50 * 60;
            setCookie("is_seller", true);
            removeCookie("seller_id");
          } else {
            setIsseller(false);
            setCookie("seller_id", data?.data?.sellerId);
            removeCookie("is_seller");
          }
        }
      } else {
        setIsseller(false);
        removeCookie("is_seller");
        removeCookie("seller_id");
      }
    } catch (error) {
      removeCookie("is_seller");
      removeCookie("seller_id");
    }
  };

  useEffect(() => {
    checkVendor();

    const handleResize = () => {
      const windowSize = window.innerWidth;
      if (windowSize > 425) {
        setNavbar(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  
  useEffect(() => {
    if (userData?._id) {
      const handleGetProfileData = async () => {
        setLoading(true);
        try {
          const userId = {
            id: userData._id,
          };
          const response = await axios.get(`${apiurl}/user/get/${userId.id}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          });
    
          if (response.data.success === true) {
            setLoading(false);
            setGetProfileData(response.data.response);
            setCookie('tile', response.data.response?.hero_img);
          } else {
            console.error("Failed to get the profile details");
            setLoading(false);
          }
        } catch (error) {
          console.error("Error fetching profile data:", error.message);
          setLoading(false);
        }
      };
    
      handleGetProfileData();
    }
  }, [userData,token]);
  
  const handleNotificationCount = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiurl}/notifications/getNotifications/${decodedToken?.user?._id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      // console.log("user notification data >>>>>", response?.data?.notifications);
      if (response.data.success === true) {
        const filteredNotifications =  response?.data?.notifications?.filter((item) => item?.author === decodedToken?.user?._id);
        // console.log("filteredNotifications",filteredNotifications);
        setCookie('nt_ctn',filteredNotifications?.length);
      }
    } catch (error) {
      setLoading(false);
      console.error("Notification Error", error);
    }
  };
  useEffect(()=>{
    if(decodedToken?.user?._id){
      handleNotificationCount();
    }
  },[decodedToken?.user?._id,token]);

  const searchInputRef = useRef(null);

  useEffect(() => {
    if (searchTab) {
      searchInputRef.current?.focus();
    }
  }, [searchTab]);

// const alertNotification = () => {
//     // Create or reuse an AudioContext
//     let audioContext = new (window.AudioContext || window.webkitAudioContext)();
  
//     // Ensure the AudioContext is in 'running' state
//     if (audioContext.state === "suspended") {
//       audioContext.resume().then(() => playSound(audioContext));
//     } else {
//       playSound(audioContext);
//     }
  
//     // Show notification toast
//     toast.success("You have a notification from chat");
//   };
  
//   const playSound = (audioContext) => {
//     fetch('https://savnewstorage.s3.us-west-1.amazonaws.com/message_tone1.mp3') // Ensure correct file path
//       .then(response => response.arrayBuffer())
//       .then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer))
//       .then(audioBuffer => {
//         const source = audioContext.createBufferSource();
//         source.buffer = audioBuffer;
//         source.connect(audioContext.destination);
//         source.start(0);
//       })
//       .catch(error => console.error("Audio play error:", error));
//   };

//   useEffect(() => {
//       socket.on("receive-message", handleNotificationCount);
//       socket.on("receive-message", alertNotification);
//       return () => {
//         socket.off("receive-message", handleNotificationCount);
//         socket.off("receive-message", alertNotification);
//       };
//     }, []);

const audioRef = useRef(null); // Reference to the audio element

const alertNotification = () => {
  if (audioRef.current) {
    audioRef.current.play().catch((error) => {
      console.error("Audio play error:", error);
    });
  }
  toast.success("You have a notification from chat");
};

useEffect(() => {
  socket.on("receive-message", alertNotification);
  return () => {
    socket.off("receive-message", alertNotification);
  };
}, []);


  return (
    <div className="w-full bg-white shadow ">
      <audio ref={audioRef} preload="auto">
        <source src="https://savnewstorage.s3.us-west-1.amazonaws.com/message_tone1.mp3" type="audio/mp3" />
      </audio>
      <div className="flex items-center justify-between sm:px-5 px-1 relative sm:my-0 w-full">
        <div className="flex w-1/3 sm:w-[20%]">
          <Link
            to={`https://savageseller.com/`}
            className="sm:px-4 px-2 py-2  transition-all duration-300 hover:translate-x-1 outline-none"
          >
            <img
              src="/assets/savageseller-logo.jpg"
              alt="logo"
              className="sm:w-auto w-full h-auto rounded-full"
            />
          </Link>
        </div>
        
        <div className="justify-end sm:items-center sm:flex relative w-[90%] flex">

          {decodedToken?.user?.firstname && (
            <>
              <button className="px-2 focus:outline-none" onClick={()=> {setSearchTab(true);setNavbar(false)}}>
                <FaSearch className="sm:hidden block w-5 h-5 text-gray-600" />
              </button>
              <Link
                to="/profile/"
                state={{
                  tabname: "feed",
                }}
                className="items-center justify-center w-auto mr-5 relative flex sm:hidden"
              >
                  {/* <NotificationsNoneIcon sx={{ fontSize: 30 }}  className="sm:hidden block w-8 h-8" />
                  {getNotifications > 0 && (
                    <span className="w-5 h-5 bg-red-500 text-white absolute top-1 right-0 inline-flex items-center justify-center rounded-full font-bold text-xs transition-all duration-700">{getNotifications}</span>
                  )} */}
                  <NotificationsNoneIcon sx={{ fontSize: 28 }}  className="w-8 h-8" />
                    {getNotifications > 0 && (
                      <span className="w-[10px] h-[10px] bg-red-500 text-white absolute top-[13px] right-[3px] m-[1px] border-[1px] border-white inline-flex items-center justify-center rounded-full font-bold text-xs transition-all duration-700"></span>
                    )}
              </Link>
              <Link
                to="https://savageseller.com/"
                className="flex items-center justify-center w-auto"
              >
                <div className="flex justify-center w-8 items-center">
                  <LiaStoreAltSolid className="sm:hidden block w-7 h-7 absolute right-16" />
                </div>
              </Link>
            </>
          )}
          
          {!userData?.firstname && !getUser ? (
            <div className="flex justify-between items-center">
              <li className="flex items-center pr-0 relative   sm:bg-[#EFF5FF] justify-center rounded-md mr-3">
                <Link
                  to="/"
                  className="py-3 px-2 overflow-hidden font-medium transition duration-300 ease-out group w-auto  mr-0"
                >
                  <button className=" font-semibold text-md sm:block hidden ">
                    GROUP HUB HOME
                  </button>
                  <div className="flex justify-end w-8">
                    <LiaStoreAltSolid className="sm:hidden block w-7 h-7 absolute right-[0px] top-0" />
                  </div>
                </Link>
                </li>
                <li className="flex items-center justify-center px-2 w-full sm:w-[160px] text-center  sm:bg-[#EFF5FF] my-1  ml-0 sm:ml-1">
                      <Link
                        to='https://multivendor.savageseller.com/' target='_blank'
                            className={` inline-flex items-center justify-center py-3 overflow-hidden font-medium transition duration-300 ease-out group sm:mr-2 mr-0`}
                            disabled={true}
                          >
                          <button className=" font-semibold text-md sm:block hidden  ">
                            SELLER PORTAL
                          </button>
                        </Link>
                      </li>

              <li className="flex gap-2 justify-end items-center p-1 lg:p-2 h-[40px] text-black">
                <Link to="/register">
                  <button className="flex items-center justify-center font-bold bg-[#E0E9F7] sm:w-24 w-16 px-1 py-2 text-[#005AE6]  border border-[#005AE6] rounded-lg sm:text-base text-xs hidden sm:block">
                    Sign Up
                  </button>
                </Link>
              </li>
              <li className="flex gap-2 justify-end items-center lg:p-2 h-[40px] text-black mx-2">
                <Link to="/login">
                  <button className="flex items-center justify-center font-bold bg-[#005AE6] sm:w-24 w-16 px-1 py-2 text-[#fff] rounded-lg sm:text-base text-xs hidden sm:block">
                    Login
                  </button>
                  <button className="sm:hidden block">
                    <FaRegUserCircle className="h-7 w-7" />
                  </button>
                </Link>
              </li>
            </div>
          ) : (
            <div className="flex items-center justify-between">
              <div className="flex items-center justify-between py-1 sm:py-3 md:py-0 md:block mr-0 sm:mr-6">
                <div className="flex justify-between flex-row-reverse w-full">
                  <div className="md:hidden ml-4">
                    <button
                      className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                      onClick={() => setNavbar(!navbar)}
                    >
                      {navbar ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 text-black"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 text-black"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4 6h16M4 12h16M4 18h16"
                          />
                        </svg>
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <div
                className={`flex-1 justify-self-center pb-3 md:block md:pb-0 md:mt-0 ${
                  navbar
                    ? "block absolute top-12 sm:py-4 bg-white min-h-screen z-50 right-0 sm:hidden w-[380px]"
                    : "hidden"
                }`}
              >
                <ul className="items-center justify-end space-y-8 md:flex md:space-x-6 md:space-y-0 px-4">
                  <div
                    className={`flex-col-reverse flex sm:flex-row justify-between w-full  ${
                      navbar ? "block mt-5" : "items-center"
                    }`}
                  >
                     {/*<li className={navbar ? "block mb-2" : ""}>
                      <button className="flex justify-between items-center font-semibold text-lg sm:mr-4 sm:ml-2 mr-0 ml-0" onClick={() => {
                        setIsNotification(!isNotification);
                      }}>
                        <IoNotificationsOutline className="text-[50px] border border-gray-300 p-3.5 rounded-full " />
                        <span className={navbar ? "block pl-3" : "hidden"}> Notification</span>
                      </button>
                    </li> */}

                    <div className="sm:flex sm:justify-between justify-center flex-column items-center sm:my-0 my-2 sm:pb-0 pb-4">
                      <li className="flex items-center justify-center px-2 w-full sm:w-[160px] bg-[#EFF5FF] text-center">
                        <Link
                          to="/"
                          className=" inline-flex items-center justify-center py-3 overflow-hidden font-medium transition duration-300 ease-out group sm:mr-2 mr-0"
                        >
                          <button className=" font-semibold text-md text-[#5c5c5c] ">
                          GROUP HUB HOME
                          </button>
                        </Link>
                        
                      </li>
                      {!isseller && (
                        <li className="relative flex items-center justify-center sm:justify-start px-2 text-center bg-[#EFF5FF] ml-0 sm:ml-4 my-1 becomeSeller text-md" >
                          <div className="inline-flex items-center w-48">
                            <div className="flex w-full items-center justify-start font-medium text-indigo-600 transition duration-300 ease-out mx-2 p-[23px]" >
                              <span className="absolute flex items-center justify-start font-semibold uppercase py-3 text-[#5c5c5c]">
                                Become a Seller
                                <FaAngleDown className="ml-2" />
                              </span>
                            </div>
                            
                            <div className="absolute left-0 top-[100%] w-full sm:w-52 bg-white rounded-md shadow-lg z-20 pt-1 dropDown text-left" >
                                <div className="py-1 bg-gray-200 border rounded-md shadow-sm text-center">
                                  <Link to="/businessRegister" className="block px-3 py-2 text-gray-800 hover:text-blue-700 border-b-2" >
                                    Business Seller
                                  </Link>
                                  <Link to="/vendorRegister" className="block px-3 py-2 text-gray-800 hover:text-blue-700" >
                                    Individual Seller
                                  </Link>
                                  <Link to="/organizationRegister" className="block px-3 py-2 text-gray-800 hover:text-blue-700" >
                                    Organizations
                                  </Link>
                                </div>
                            </div>
                          </div>
                        </li>
                      )}
                      <li className="flex items-center justify-center px-2 w-full sm:w-[160px] text-center  bg-[#EFF5FF] my-1  ml-0 sm:ml-4">
                      <Link
                        to='https://multivendor.savageseller.com/' target='_blank'
                            className={` inline-flex items-center justify-center py-3 overflow-hidden font-medium transition duration-300 ease-out group sm:mr-2 mr-0`}
                            disabled={true}
                          >
                          <button className=" font-semibold text-md text-[#5c5c5c] ">
                            SELLER PORTAL
                          </button>
                        </Link>
                      </li>
                      <li className="sm:hidden flex items-center justify-center px-2 w-full sm:w-[160px] text-center  bg-[#EFF5FF] my-1  ml-0 sm:ml-4">
                      <Link
                         to="/profile/"
                        state={{
                          tabname: "my profile",
                        }}
                            className={` inline-flex items-center justify-center py-3 overflow-hidden font-medium transition duration-300 ease-out group sm:mr-2 mr-0`}
                            disabled={true}
                            onClick={() => setNavbar(!navbar)}
                          >
                          <button className=" font-semibold text-md text-[#5c5c5c] ">
                            MY PROFILE
                          </button>
                        </Link>
                      </li>
                      <li className="sm:hidden flex items-center justify-center px-2 w-full sm:w-[160px] text-center  bg-[#EFF5FF] my-1  ml-0 sm:ml-4">
                      <Link
                        to="/profile/"
                        state={{
                          tabname: "requests",
                        }}
                        className={` inline-flex items-center justify-center py-3 overflow-hidden font-medium transition duration-300 ease-out group sm:mr-2 mr-0`}
                        disabled={true}
                        onClick={() => setNavbar(!navbar)}
                      >
                        <button className="font-semibold text-md text-[#5c5c5c]">
                          REQUESTS
                        </button>
                      </Link>

                      </li>
                      {/* {window.location.href!=="https://community.savageseller.com/"? */}
                    </div>

                    {getUser && (
                      <>
                        <div className="sm:mx-4 mx-0 sm:mt-0 mt-3 hidden sm:block">
                          <div className="relative flex items-center justify-center bg-[#EFF5FF] rounded-md md:w-[200px] w-full md:flex px-3 py-0">
                            <input
                              type="search"
                              name="searchInput"
                              className="py-3 px-4 w-full outline-none bg-[#EFF5FF] rounded-md"
                              placeholder="Search "
                              value={searchQuery}
                              onChange={(e) => handleSearch(e)}
                              // onKeyUp={handleUniversalSearch}
                              onClick={() => { setLoader(true)}}
                              
                            />
                            <button className="px-2 focus:outline-none">
                              <FaSearch className="h-4 w-4 text-gray-600" />
                            </button>
                            {searchQuery.length > 0 && searchData && (
                              <>
                                {(communities?.length > 0 ||
                                posts?.length > 0 ||
                                users?.length > 0) ? (
                                  
                                    <div className="absolute top-1/2 right-0 sm:right-0 lg:-right-[72%] z-50 my-6 mx-auto sm:w-[550px] lg:w-[750px] w-full shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] max-h-screen overflow-y-scroll border-0">
                                      <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                        <div className="flex justify-end mr-2 p-3 border-b border-solid border-gray-300 rounded-t ">
                                          <button
                                            className="bg-transparent border-0 text-black text-3xl"
                                            onClick={() => {setSearchData(false); setSearchQuery('')}}
                                          >
                                            {/* <p>{searchData.length >0 ? 'NotFound Data' : null}</p> */}

                                            <span>x</span>
                                          </button>
                                        </div>
                                        <div className="px-4 py-2">
                                          <h3 className="text-xl font=semibold mb-2 w-full">
                                            {communities?.length > 0
                                              ? "Communities"
                                              : null}
                                          </h3>
                                          {communities?.map(
                                            (community, index) => (
                                              <div className="mb-2 w-full sm:w-[48%] inline-block mx-[1%]">
                                                <div
                                                  className="py-2 px-1 flex flex-col cursor-pointer bg-[#fff] rounded-lg shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] lg:mb-0 sm:h-full hover:bg-gray-300 transition-all duration-400"
                                                  key={index}
                                                >
                                                  <div className="flex justify-between items-center">
                                                    <Link
                                                      to={`/community/${community.community_handle}`}
                                                      //target="blank"
                                                    >
                                                      <div className="flex justify-between items-center">
                                                        <img
                                                          src={community?.hero_img?community?.hero_img:"/assets/girl.png"}
                                                          alt="savage-logo"
                                                          className="w-12 h-12  rounded-full border-blue border-[5px] cursor-pointer"
                                                        />
                                                        <div className="lg:w-[80%] pl-2">
                                                          <h2 className=" cursor-pointer ">
                                                            {community?.title}
                                                          </h2>
                                                        </div>
                                                      </div>
                                                    </Link>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )}
                                          <h4 className="text-lg font-semibold mb-2 w-full">
                                            {posts?.length > 0 ? "Post" : null}
                                          </h4>
                                          {posts?.length > 0 &&
                                            posts?.map((posts, index) => (
                                              <div
                                                className="px-3 py-2  flex-col cursor-pointer bg-[#fff] rounded-lg shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] mb-2  sm:h-full hover:bg-gray-300 transition-all duration-400 w-full  inline-block mx-[1%]"
                                                key={index}
                                              >
                                                <Link
                                                  to={`/community/${posts.community.community_handle}/posts/${posts._id}`}
                                                 // target="blank"
                                                >
                                                  <div>
                                                    <h2>{posts.title}</h2>
                                                    <p className="text-[#000]">
                                                      <span className="text-xs text-gray-500 font-semibold line-clamp-2">
                                                        {posts.content}
                                                      </span>
                                                    </p>
                                                  </div>
                                                </Link>
                                              </div>
                                            ))}
                                          <h4 className="text-lg font-semibold my-2">
                                            {users?.length > 0 ? "Users" : null}
                                          </h4>
                                          {users &&
                                            users?.map((user, index) => (
                                              <div
                                                className="px-3 py-2 flex-col cursor-pointer bg-[#fff] rounded-lg shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] mb-2 sm:h-full hover:bg-gray-300 transition-all duration-400 sm:w-[48%] w-[100%]  inline-block mx-[1%]"
                                                key={index}
                                              >
                                                <Link
                                                  to={`/profile/${user._id}`}
                                                  //target="blank"
                                                >
                                                  <div className="">
                                                    <h2 className="w-full flex justify-start items-center">
                                                    <img
                                                          src={user?.hero_img?user?.hero_img:"profile.png"}
                                                          alt="savage-logo"
                                                          className="w-12 h-12  rounded-full border-blue border-[5px] cursor-pointer"
                                                        />
                                                        <p className="pl-1">
                                                      {user.name}
                                                      <span className="text-xs text-gray-500 font-semibold line-clamp-2">
                                                        {user.userHandle}
                                                      </span>
                                                      </p>
                                                    </h2>
                                                   
                                                  </div>
                                                </Link>
                                              </div>
                                            ))}
                                        </div>
                                      </div>
                                    </div>
                                ) : (
                                    <div className="absolute top-[54%] right-0 sm:right-0 lg:-right-[72%] z-50 my-6 mx-auto sm:w-[550px] lg:w-[750px] w-full shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] h-fit border-0">
                                      <div className="shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                        {loader ? (
                                        <p className="sm:text-xl text-xl font-semibold my-6 text-center flex items-center justify-center">
                                        <svg
                                          width="34px"
                                          height="34px"
                                          xmlns="http://www.w3.org/2000/svg"
                                          xlink="http://www.w3.org/1999/xlink"
                                          stroke="#000000"
                                          viewBox="0 0 100 100"
                                          preserveAspectRatio="xMidYMid"
                                        >
                                          <circle cx="75" cy="50" fill="#000000" r="6.39718"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.875s" ></animate> </circle> <circle cx="67.678" cy="67.678" fill="#000000" r="4.8"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.75s" ></animate> </circle> <circle cx="50" cy="75" fill="#000000" r="4.8"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.625s" ></animate> </circle> <circle cx="32.322" cy="67.678" fill="#000000" r="4.8"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.5s" ></animate> </circle> <circle cx="25" cy="50" fill="#000000" r="4.8"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.375s" ></animate> </circle> <circle cx="32.322" cy="32.322" fill="#000000" r="4.80282" > <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.25s" ></animate> </circle> <circle cx="50" cy="25" fill="#000000" r="6.40282"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.125s" ></animate> </circle> <circle cx="67.678" cy="32.322" fill="#000000" r="7.99718" > <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="0s" ></animate> </circle>
                                        </svg>{" "}
                                      <span className="mt-[2px]">Loading</span>
                                        </p>) : (<>
                                          <p className="sm:text-xl text-xl font-semibold my-6 text-center flex items-center justify-center">No results found. Please try a different search term</p>
                                        <button className="bg-transparent border-0 text-black text-2xl absolute top-1 right-3" onClick={() => {setSearchData(false); setSearchQuery('')}} >
                                          <span>x</span>
                                        </button>
                                        </>)}
                                        
                                      </div>
                                    </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>

                        <li className="flex justify-start items-center text-black sm:justify-end mb-1 sm:w-1/4 w-full">
                          <Link to="/profile" className="outline-none">
                            <div className="flex items-center justify-center font-semibold rounded-md w-full">
                              <div className="w-full">
                                <img
                                src={getProfileImage ? getProfileImage : decodedToken.user?.hero_img ? decodedToken.user?.hero_img :  "/profile.png"}
                                  alt="savage-logo"
                                  className="w-[50px] rounded-full border-2 border-[#005AE6] cursor-pointer "
                                />
                              </div>
                              <p className="pl-3 text-lg"> 
                                <span className="block font-semibold capitalize"> 
                                  {getprofiledata.firstname ? getprofiledata.firstname : decodedToken.user.firstname?decodedToken.user.firstname:userData?.firstname?userData?.firstname:""} 
                                </span> 
                                </p>
                            </div>
                          </Link>
                        </li>
                      </>
                    )}
                    {/* <li className={navbar ? 'block absolute top-0 right-4 ' : 'hidden'}>
                      <span className="font-semibold text-lg text-[#005AE6]">
                        Hi {userData?.firstname}
                      </span>
                    </li> */}
                  </div>
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className={`fixed top-0 right-0 mt-2 w-64 z-10 transition-transform duration-700 ease-in-out ${
          isNotification ? "translate-x-0" : "translate-x-full "
        }`}
      >
        {isNotification ? (
          <Notification setIsNotification={setIsNotification} />
        ) : null}
      </div>
      {searchTab && (
        <>
        <div className="sm:mx-4 mx-0 sm:mt-0 mt-0 z-50 absolute top-0 left-0 right-0">
          <div className="relative flex items-center justify-center bg-[#EFF5FF] rounded-md md:w-[200px] w-full md:flex px-3 py-0 removeCloseIcon">
            <button className="px-2 focus:outline-none">
              <FaSearch className="h-4 w-4 text-gray-600" />
            </button>
            <input
              type="search"
              name="searchInput"
              ref={searchInputRef}
              className="py-3 px-4 w-full outline-none bg-[#EFF5FF] rounded-md"
              placeholder="Search "
              value={searchQuery}
              onChange={(e) => handleSearch(e)}
              // onKeyUp={handleUniversalSearch}
              onClick={() => { setLoader(true)}}
              
            />
            <button className="bg-transparent border-0 text-red-500 text-2xl" 
            onClick={() => {setSearchTab(false);setSearchQuery("")}} >
              <span>x</span>
            </button>
            {searchQuery.length > 0 && searchData && (
              <>
                {communities?.length > 0 ||
                posts?.length > 0 ||
                users?.length > 0 ? (
                  
                    <div className="absolute top-1/2 right-0 sm:right-0 lg:-right-[72%] z-50 my-6 mx-auto sm:w-[550px] lg:w-[750px] w-full shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] max-h-screen overflow-y-scroll border-0">
                      <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex justify-end mr-2 p-3 border-b border-solid border-gray-300 rounded-t ">
                          <button
                            className="bg-transparent border-0 text-black sm:text-3xl text-2xl"
                            onClick={() => {setSearchData(false); setSearchQuery('')}}
                          >

                            <span>x</span>
                          </button>
                        </div>
                        <div className="px-4 py-2 min-h-screen">
                          <h3 className="text-xl font=semibold mb-2 w-full">
                            {communities?.length > 0
                              ? "Communities"
                              : null}
                          </h3>
                          {communities?.map(
                            (community, index) => (
                              <div className="mb-2 w-full sm:w-[48%] inline-block mx-[1%]">
                                <div
                                  className="py-2 px-1 flex flex-col cursor-pointer bg-[#fff] rounded-lg shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] lg:mb-0 sm:h-full hover:bg-gray-300 transition-all duration-400"
                                  key={index}
                                >
                                  <div className="flex justify-between items-center">
                                    <Link
                                      to={`/community/${community.community_handle}`}
                                      //target="blank"
                                    >
                                      <div className="flex justify-between items-center">
                                        <img
                                          src={community?.hero_img?community?.hero_img:"/assets/girl.png"}
                                          alt="savage-logo"
                                          className="w-12 h-12  rounded-full border-blue border-[5px] cursor-pointer"
                                        />
                                        <div className="lg:w-[80%] pl-2">
                                          <h2 className=" cursor-pointer ">
                                            {community?.title}
                                          </h2>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                          <h4 className="text-lg font-semibold mb-2 w-full">
                            {posts?.length > 0 ? "Post" : null}
                          </h4>
                          {posts?.length > 0 &&
                            posts?.map((posts, index) => (
                              <div
                                className="px-3 py-2  flex-col cursor-pointer bg-[#fff] rounded-lg shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] mb-2  sm:h-full hover:bg-gray-300 transition-all duration-400 w-full  inline-block mx-[1%]"
                                key={index}
                              >
                                <Link
                                  to={`/community/${posts.community.community_handle}/posts/${posts._id}`}
                                  // target="blank"
                                >
                                  <div>
                                    <h2>{posts.title}</h2>
                                    <p className="text-[#000]">
                                      <span className="text-xs text-gray-500 font-semibold line-clamp-2">
                                        {posts.content}
                                      </span>
                                    </p>
                                  </div>
                                </Link>
                              </div>
                            ))}
                          <h4 className="text-lg font-semibold my-2">
                            {users?.length > 0 ? "Users" : null}
                          </h4>
                          {users &&
                            users?.map((user, index) => (
                              <div
                                className="px-3 py-2 flex-col cursor-pointer bg-[#fff] rounded-lg shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] mb-2 sm:h-full hover:bg-gray-300 transition-all duration-400 sm:w-[48%] w-[100%]  inline-block mx-[1%]"
                                key={index}
                              >
                                <Link
                                  to={`/profile/${user._id}`}
                                  //target="blank"
                                >
                                  <div className="">
                                    <h2 className="w-full flex justify-start items-center">
                                    <img
                                          src={user?.hero_img?user?.hero_img:"profile.png"}
                                          alt="savage-logo"
                                          className="w-12 h-12  rounded-full border-blue border-[5px] cursor-pointer"
                                        />
                                        <p className="pl-1">
                                      {user.name}
                                      <span className="text-xs text-gray-500 font-semibold line-clamp-2">
                                        {user.userHandle}
                                      </span>
                                      </p>
                                    </h2>
                                    
                                  </div>
                                </Link>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                ) : (
                    <div className="absolute top-[54%] right-0 sm:right-0 lg:-right-[72%] z-50 my-6 mx-auto sm:w-[550px] lg:w-[750px] w-full shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] h-fit border-0">
                      <div className="shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                      {loader ? (
                        <p className="sm:text-xl text-xl font-semibold my-6 text-center flex items-center justify-center">
                        <svg
                          width="34px"
                          height="34px"
                          xmlns="http://www.w3.org/2000/svg"
                          xlink="http://www.w3.org/1999/xlink"
                          stroke="#000000"
                          viewBox="0 0 100 100"
                          preserveAspectRatio="xMidYMid"
                        >
                          <circle cx="75" cy="50" fill="#000000" r="6.39718"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.875s" ></animate> </circle> <circle cx="67.678" cy="67.678" fill="#000000" r="4.8"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.75s" ></animate> </circle> <circle cx="50" cy="75" fill="#000000" r="4.8"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.625s" ></animate> </circle> <circle cx="32.322" cy="67.678" fill="#000000" r="4.8"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.5s" ></animate> </circle> <circle cx="25" cy="50" fill="#000000" r="4.8"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.375s" ></animate> </circle> <circle cx="32.322" cy="32.322" fill="#000000" r="4.80282" > <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.25s" ></animate> </circle> <circle cx="50" cy="25" fill="#000000" r="6.40282"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.125s" ></animate> </circle> <circle cx="67.678" cy="32.322" fill="#000000" r="7.99718" > <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="0s" ></animate> </circle>
                        </svg>{" "}
                      <span className="mt-[2px]">Loading</span>
                        </p>) : (<>
                          <p className="sm:text-xl text-[16px] p-2 sm:p-0 font-semibold my-6 text-center flex items-center justify-center">No results found. Please try a different search term</p>
                        <button className="bg-transparent border-0 text-black text-xl absolute top-1 right-3" onClick={() => {setSearchData(false); setSearchQuery('');}} >
                          <span>x</span>
                        </button>
                        </>)}
                      </div>
                    </div>
                )}
              </>
            )}
          </div>
        </div>
        </>
      )}
    </div>
  );
}