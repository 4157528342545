import React, { useEffect, useState, useRef, useContext } from "react";
import axios from "axios";
import { useUser } from "../contexts/UserProvider.js";
import { socket } from "../config/socket.js";
import { getCookie } from "../config/coockies.js";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CallContext } from "../contexts/CallContext.js";
import { AudioCallContext } from "../contexts/audioCallContext.js";
import { GroupCallContext } from "../contexts/groupCallContext.js";
import { GetTimeAgo } from "../components/getTime.js";
import { GrGroup } from "react-icons/gr";
import { RiUser3Line } from "react-icons/ri";
import { FaUserLock } from "react-icons/fa";
import { toast } from "react-toastify";
import { IoArrowBackSharp } from "react-icons/io5";
import { MdAddPhotoAlternate } from "react-icons/md";
import { v4 as uuidv4 } from "uuid";
import { awsKeyId, awsSecretKey } from "../config/config";
import DOMPurify from "dompurify";
import { BsEmojiSmileFill } from "react-icons/bs";
import Picker from "emoji-picker-react";
import { apiurl } from "../config/config";
import { NewGroupCallContext } from "../contexts/NewGroupCall.js";
import { GetDate } from "../components/GetDate.js";
import { GetTimeforchat } from "../components/GetTimeforchat.js";
import { IoIosNotifications } from "react-icons/io";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import CircularProgress from "@mui/material/CircularProgress";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const aws = require("aws-sdk");

export default function ChatBox() {
  const { Calluser } = useContext(CallContext);
  const { AudioCalluser } = useContext(AudioCallContext);
  const { joinGroupCall } = useContext(GroupCallContext);

  let User = useUser();
  const { userData } = useUser();
  const token = getCookie("savage_token");
  const [selectedChat, setSelectedChat] = useState(null);
  const [newChatPopup, setNewChatPopup] = useState(false);
  const [createGroupPopup, setCreateGroupPopup] = useState(false);
  const [createGroupresult, setCreateGroupresult] = useState(false);
  const [selectedChatName, setSelectedChatName] = useState(null);
  const [selectedChatImage, setSelectedChatImage] = useState(null);
  const [groupMemberCount, setGroupMemberCount] = useState(null);
  const [creator, setCreator] = useState(null);
  const [userList, setuserList] = useState([]);
  const [chatList, setchatList] = useState([]);
  const [isGroup, setIsGroup] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const [groupType, setGroupType] = useState("");
  const [groupList, setGroupList] = useState([]);
  const [newGroupName, setNewGroupName] = useState("");
  const [groupMembers, setGroupMembers] = useState([]);
  const [chatType, setChatType] = useState("chat");
  const [getAllPublicGroup, setGetAllPublicGroup] = useState([]);
  const [getAllPrivateGroup, setGetAllPrivateGroup] = useState([]);
  const [privateGroupPop, setPrivateGroupPop] = useState(false);
  const [isPrivateGroup, setIsPrivateGroup] = useState(false);
  const [groupPassword, setGroupPassword] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [groupMemberSearchTerm, setGroupMemberSearchTerm] = useState("");
  const [newChatUserSerchTerm, setNewChatUserSerchTerm] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserGroup, setSelectedUserGroup] = useState([]);
  const [message, setMessage] = useState("");
  const [newMessage, setNewMessage] = useState("");
  const [groupname, setGroupname] = useState("");
  const [loading, setloading] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [isCheckPrivateGroupMember, setIsCheckPrivateGroupMember] = useState();
  const [isDesktopView, setIsDesktopView] = useState(window.innerWidth > 768);
  const [privatepwd, setPrivatepwd] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [isFetched, setIsFetched] = useState(true);
  
  const handleResize = () => {
    setIsDesktopView(window.innerWidth > 768);
  };
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    fetchUserList();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const fetchChatsList = async () => {
    let senderId = User?.userData?._id;
    const config = {
      url: `${apiurl}/message/chats/${senderId}`,
      method: "GET",
      maxBodyLength: Infinity,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    try {
      const response = await axios.request(config);
      const data = await response.data;
      if(data){
        setchatList(data.users);
      }
    } catch (error) {
      console.error("Error fetching chats:", error);
    }
  };
  const fetchUserList = () => {
    axios
      .get(`${apiurl}/user/all`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        const users = res?.data?.users;
        const sortedUsers = users.sort((a,b)=> a.name.localeCompare(b.name)).filter((user)=> user?._id !== User?.userData?._id);
        setuserList(sortedUsers);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleGetNotifications = async () => {
    try{
      const response = await axios.get(
        `${apiurl}/notifications/getNotifications/${userData?._id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if(response?.data){
        setNotificationData(response?.data?.notifications || []);
      }

    } catch (error) {

      console.error(error);
    }
  }
  
  useEffect(() => {
    fetchUserList();
    fetchChatsList();
    handleGetNotifications();
    fetchGroupList();
  }, [isChange]);

  useEffect(() => {
    socket.on("receive-message", fetchChatsList);
    socket.on("receive-message", fetchGroupList);
    socket.on("receive-message",handleGetNotifications)
    return () => {
      socket.off("receive-message", fetchChatsList);
      socket.off("receive-message", fetchGroupList);
      socket.off("receive-message",handleGetNotifications)
    };
  }, []);

  const fetchGroupList = () => {
    axios
      .get(
        `${apiurl}/message/getgroupsbyuserid?userId=${User?.userData?._id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      )
      .then((res) => {
        setGroupList(res.data.groups);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleGetAllPublicGroup = async () => {
    try {
      const response = await axios.get(`${apiurl}/message/get-public-groups`);
      if (response.data.success === true) {
        setGetAllPublicGroup(response.data?.groups);
      } else {
        console.error("get all public group error");
      }
    } catch (error) {
      console.error("get all public group", error);
    }
  };
  const handleGetAllPrivateGroup = async () => {
    try {
      const response = await axios.get(`${apiurl}/message/get-private-groups`);
      if (response.data.success === true) {
        setGetAllPrivateGroup(response.data?.groups);
      } else {
        console.error("get all private group error");
      }
    } catch (error) {
      console.error("get all private group", error);
    }
  };

  useEffect(() => {
    fetchChatsList();
    fetchGroupList();
  }, [selectedChat,isChange]);

  useEffect(() => {
    handleGetAllPublicGroup();
    handleGetAllPrivateGroup();
  }, []);

  const handleJoinGroup = async (group) => {
    setloading(true);
    setSelectedGroup(group);
    try {
      const data = {
        groupId: group?._id,
        userId: userData._id,
        password: groupPassword || "",
      };
      const response = await axios.post(`${apiurl}/message/joingroup`, data);
      if (response.data.success === true) {
        setGroupPassword("");
        setloading(false);
        toast.success(response.data.message);
        fetchChatsList();
        fetchGroupList();
        handleGetAllPublicGroup();
        handleGetAllPrivateGroup();
        handleGetNotifications();
        setPrivateGroupPop(false);
      } else {
        toast.error("Password required to join. Please check your email.");
        setloading(false);
      }
    } catch (error) {
      setloading(false);
      console.error("An error occurred while joining the group:", error);
    }
  };

  const handleSubmitJoinGroup = async () => {
    setIsVerify(true);
    try {
      const data = {
        groupId: selectedGroup?._id,
        userId: userData._id,
        password: groupPassword || "",
      };
      const response = await axios.post(`${apiurl}/message/joingroup`, data);
      if (response.data.success === true) {
        setGroupPassword("");
        setIsVerify(false);
        toast.success(response.data.message);
        fetchChatsList();
        fetchGroupList();
        handleGetAllPublicGroup();
        handleGetAllPrivateGroup();
        handleGetNotifications();
        setPrivateGroupPop(false);
        setSelectedGroup(null);
        openChat(
          selectedGroup?._id,
          selectedGroup?.name,
          "",
          selectedGroup?.members.length,
          setIsCheckPrivateGroupMember(true),
          selectedGroup?.members,
          selectedGroup?.creator
        );
      } else {
        toast.error("Password required to join. Please check your email.");
        setIsVerify(false);
      }
    } catch (error) {
      setIsVerify(false);
      console.error("An error occurred while joining the group:", error);
    }
  };
  const senderId = User?.userData?._id;
  const receiverId = selectedChat;
  const senderName = User?.userData?.firstname;
  const receiverName = selectedChatName;
  const openChat = (
    receiverId,
    firstname,
    hero_img,
    groupMemberCount,
    groupMembers,
    creator
  ) => {
    setGroupMembers(groupMembers);
    setSelectedChat(receiverId);
    setSelectedChatName(firstname);
    setSelectedChatImage(hero_img);
    setGroupMemberCount(groupMemberCount);
    setCreator(creator);
  };
  const handleBackToChatList = () => {
    setSelectedChat(null);
    setSelectedChatName(null);
  };
  const handleUserSelect = (user) => {
    setSelectedUser(user);
    setSearchTerm("");
    setFilteredUsers([]);
  };

  const createNewChatMessageNotification = async (senderId, receiverId) => {
    const data = {
      userId: {
        _id: senderId,
      },
      type: "MESSAGE",
      message: "sent you a message",
      author: {
        _id: receiverId,
      },
    };
    try {
      const response = await axios.post(
        `${apiurl}/notifications/createNotification`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
    } catch (error) {
      console.error("sent message Notification error", error);
    }
  };

  const newChatHandle = async (receiver) => {
    if (!newMessage || newMessage.trim() === "") {
      toast.warn("Please add some message or text.");
      return;
    }
    setIsSent(true);
    try {
      const response = await axios.post(`${apiurl}/message/send`, {
        senderId: User?.userData?._id,
        receiverId: receiver._id,
        content: newMessage.trim(),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      setSelectedChat(null);
      setSelectedChatName(null);
      if(response?.data){
        setIsSent(false);
        setIsChange((prev) => !prev);
        await createNewChatMessageNotification(User?.userData?._id,receiver?._id);
        // window.location.reload();
      }
      fetchChatsList();
      fetchGroupList();
      handleGetAllPublicGroup();
      handleGetAllPrivateGroup();
      handleGetNotifications();
      openChat(receiver?._id, receiver?.name, receiver?.hero_img);
      setNewChatPopup(false);
      setNewMessage("");
      setNewChatUserSerchTerm("");
      setSelectedUser(null);
      setChatType('chat');
    } catch (error) {
      setIsSent(false);
      console.error("Error sending message:", error);
      toast.error("Failed to send message. Please try again.");
    }
  };
  const handleCloseNewChat = ()=> {
    setNewChatPopup(false);
    setNewMessage("");
    setNewChatUserSerchTerm("");
    setSelectedUser(null);
  }
  const handleCheckboxChange = (userId) => {
    const isChecked = selectedUserGroup.includes(userId);
    if (isChecked) {
      setSelectedUserGroup(selectedUserGroup.filter((id) => id !== userId));
    } else {
      setSelectedUserGroup([...selectedUserGroup, userId]);
    }
  };
  const handleCloseCreateGroupPopup = () => {
    setCreateGroupPopup(false);
    setSelectedUserGroup([]);
    setGroupname("");
    setGroupType("");
    setGroupMemberSearchTerm("");
  };
  
  const createGroupNotification = async (senderId, receiverId, groupType) => {
    const data = {
      userId: {
        _id: senderId,
      },
      type: "CREATE_GROUP",
      message: `created a new ${groupType ? groupType : ''} group`,
      author: {
        _id: receiverId,
      },
    };
    try {
      const response = await axios.post(
        `${apiurl}/notifications/createNotification`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
    } catch (error) {
      console.error("sent message Notification error", error);
    }
  };

  const createGroupe = () => {
    setloading(true);
    if(groupType === "private"){
      if(!privatepwd || privatepwd === ''){
        toast.warn("Password is required for a private group");
        setloading(false);
        return;
      }
    }
    if (selectedUserGroup.length > 0 && groupname && groupType) {
      let data = JSON.stringify({
        groupName: groupname,
        groupType: groupType,
        members: [...selectedUserGroup, User?.userData?._id],
        createdBy: User?.userData?._id,
        password: privatepwd,
      });
      let config = {
        method: "post",
        url: `${apiurl}/message/creategroup`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          if(response?.data?.group){
            const groupCreator = response?.data?.group?.creator;
            const groupId = response?.data?.group?._id;
            setIsChange((prev) => !prev);
            createGroupNotification(groupCreator,groupId,groupType);
          }
          setloading(false);
          setCreateGroupresult(true);
          setCreateGroupPopup(false);
          setSelectedUserGroup([]);
          setGroupMemberSearchTerm("");
          setGroupType("")
          setPrivatepwd("")
          setChatType(groupType === 'public' ? 'public-group' : 'private-group');
          fetchChatsList();
          fetchGroupList();
          handleGetAllPublicGroup();
          handleGetAllPrivateGroup();
          handleGetNotifications();
          const group = response?.data?.group;
          openChat(
            group?._id,
            group?.name,
            "",
            group?.members?.length,
            group?.members,
            group?.creator
          );
        })
        .catch((error) => {
          console.log(error);
          setloading(false);
        });
    } else {
      if (!groupname) {
        toast.warn("Please add group name");
        setloading(false);
      } else if (selectedUserGroup.length < 1) {
        toast.warn("Please add some members ");
        setloading(false);
      } else if (!groupType) {
        toast.warn("Please select group type");
        setloading(false);
      }
    }
  };
  const filteredChatList = chatList
    .filter((user) => {
      return user.name.toLowerCase().includes(searchTerm.toLowerCase());
    })

    .sort((a, b) => {
      const dateA = new Date(a.lastMessage?.createdAt);
      const dateB = new Date(b.lastMessage?.createdAt);

      if (!a.lastMessage || !a.lastMessage.createdAt) return 1;
      if (!b.lastMessage || !b.lastMessage.createdAt) return -1;

      return dateB - dateA;
    });

  const filteredGroupList = groupList.filter((user) => {
    return user.name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const combinedChatList = [...filteredChatList, ...filteredGroupList];
  useEffect(()=>{
    if(combinedChatList.length < 1){
      setIsFetched(true);
    }else{
      setIsFetched(false);
    }
  },[combinedChatList.length])

  // Sort the combined array by the latest message or creation date
  const sortedCombinedChatList = combinedChatList.sort((a, b) => {
    const dateA = new Date(a.lastMessage?.createdAt || a.createdAt);
    const dateB = new Date(b.lastMessage?.createdAt || b.createdAt);

    return dateB - dateA; // Sort in descending order of time
  });

  const isPrivateGroupMember = getAllPrivateGroup.map((item) =>
    item.members.some((member) => member._id === userData._id)
  );
  const isPublicGroupMember = getAllPublicGroup.map((item) =>
    item.members.some((member) => member._id === userData._id)
  );


  const [notificationData, setNotificationData] = useState([]);
  const [shownotification,setShownotification]=useState(false)

  const handleStartChat = ()=> {
    // window.location.reload();
    setCreateGroupresult(false);
    setIsChange((prev) => !prev);
    fetchChatsList();
    fetchGroupList();
    handleGetAllPublicGroup();
    handleGetAllPrivateGroup();
    handleGetNotifications();
  }
  // console.log("notificationData__",notificationData);
  
useEffect(()=>{
  handleGetNotifications();
},[])

  return (
    <div className="flex justify-between h-screen flex-col md:flex-row">
      {newChatPopup === true ? (
        <>
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75 z-30">
            <div className="bg-white sm:p-6 p-2 rounded-xl shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] text-center flex justify-start items-center flex-col sm:w-2/5 w-11/12 slideBottom1">
              <div className="flex justify-between items-center w-full">
                <p className="text-lg">New Chat</p>
                <button 
                  className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600" 
                  onClick={handleCloseNewChat}
                  >
                  X
                </button>
              </div>
              <div className="w-full p-2">
                <div className="my-1 p-1 flex bg-[#fff] border border-gray-400 w-full rounded-lg">
                  <input
                    type="text"
                    className="px-2 py-2 w-full focus:outline-none"
                    placeholder="Search Users . . ."
                    value={newChatUserSerchTerm}
                    onChange={(e) => {
                      setNewChatUserSerchTerm(e.target.value);
                      setFilteredUsers(
                        userList.filter((user) =>
                          user?.name
                            ?.toLowerCase()
                            .includes(e.target?.value?.toLowerCase())
                        )
                      );
                    }}
                  />
                  <button className="px-4 focus:outline-none">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 512 512"
                      className="text-[#000]"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
                    </svg>
                  </button>
                </div>

                {(filteredUsers.length > 0 || userList !== "") && (
                  <ul className="sm:mt-4 mt-2 w-full bg-gray-100 border border-gray-300 rounded-lg shadow-md overflow-y-scroll h-40">
                    {(filteredUsers.length > 0 ? filteredUsers : userList).map(
                      (user) => (
                        <li
                          key={user?._id}
                          onClick={() => handleUserSelect(user)}
                          className={`flex justify-start items-center px-3 py-2 bg-gray-100 hover:bg-gray-200 transition-all duration-300 cursor-pointer border-b-2  ${
                            selectedUser === user
                              ? "bg-blue-500 text-blue-600"
                              : ""
                          }`}
                        >
                          <div className="relative self-center mr-3 border-[1px] border-gray-500 rounded-full p-1">
                            <img
                              src={user.hero_img}
                              className="w-12 h-12 flex items-center justify-center rounded-full border border-gray-500 font-bold text-uppercase bg-slate-300"
                              alt=""
                            />
                          </div>
                          <div className="flex flex-col w-3/4 items-start">
                            <div className="sm:text-lg text-sm">
                              {user?.name}
                            </div>
                            {/* <p className="sm:text-sm text-xs">{user.email}</p> */}
                          </div>
                        </li>
                      )
                    )}
                  </ul>
                )}
                {selectedUser && (
                  <div className="mt-2">
                    <div className="flex mt-5 items-center">
                      <img
                        src={selectedUser?.hero_img}
                        className="object-cover h-24 w-24 rounded-lg border border-gray-400"
                        alt=""
                      />
                      <div className="text-start ml-3">
                        <div className="sm:text-xl text-lg font-semibold">
                          {selectedUser?.name}
                        </div>
                        <div className="sm:text-base text-sm">
                          {selectedUser?.email}
                        </div>
                        <span className="sm:text-base text-sm">{selectedUser?.location}</span>
                      </div>
                    </div>
                    <div className="flex justify-center items-center mt-3">
                      <input
                        type="text"
                        placeholder="Type your message"
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        className="border border-gray-300 p-2 rounded-md mt-2 w-full"
                      /> 
                      <button onClick={() => newChatHandle(selectedUser)} className="ml-2 px-8 py-2 bg-blue-500 text-white rounded-md mt-2" >
                        {isSent ? 'Sending...' : 'Send'}
                      </button>
                    </div>
                    <div className="mt-5">
                      <p className="sm:text-lg text-sm">
                        "Hey there! 🌟 Before we dive in, let's keep it friendly
                        and respectful. Enjoy the chat! 👋"
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : null}

      {createGroupPopup === true ? (
        <>
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75 z-30">
            <div className="bg-white sm:p-6 p-3 rounded-xl shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] text-center flex justify-start items-center flex-col sm:w-2/5 w-11/12 slideBottom1">
              <div className="flex justify-between items-center w-full">
                <p className="text-xl font-semibold">Create New Group</p>
                <button 
                  className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600" 
                  onClick={handleCloseCreateGroupPopup}
                  >
                  X
                </button>
              </div>
              <div className="w-full mt-2">
                <div className="text-start">
                  <label
                    htmlFor="groupName"
                    className="text-start m-auto mb-1 text-lg"
                  >
                    Group Name :
                  </label>
                  <input
                    id="groupName"
                    type="text"
                    className="p-2 w-full focus:outline-none border border-gray-400 rounded-lg mb-2"
                    placeholder="Group Name"
                    onChange={(e) => setGroupname(e.target.value)}
                  />
                </div>

                <div className="p-1 flex bg-[#fff] border border-gray-400 w-full rounded-lg">
                  <input
                    type="text"
                    className="p-1 w-full focus:outline-none"
                    placeholder="Search Users . . ."
                    value={groupMemberSearchTerm}
                    onChange={(e) => {
                      setGroupMemberSearchTerm(e.target.value);
                      setFilteredUsers(
                        userList.filter((user) => user?.name?.toLowerCase().includes(e.target?.value?.toLowerCase()) ) );
                    }}
                  />
                </div>

                <div className="flex items-center justify-between sm:w-3/5 w-11/12">
                  <div className="flex items-center justify-between mt-2">
                    <label
                      htmlFor="groupName"
                      className="text-start m-auto mb-1 text-gray-700 mt-1"
                    >
                      Public Group
                    </label>
                    <input
                      type="radio"
                      className="mx-2"
                      name="groupType"
                      value="public"
                      onClick={() => {setGroupType("public");setPrivatepwd("")}}
                      defaultChecked={groupType === "public"}
                    />
                  </div>
                  <div className="flex items-center justify-between mt-2">
                    <label
                      htmlFor="groupName"
                      className="text-start m-auto mb-1 text-gray-700 mt-1"
                    >
                      Private Group
                    </label>
                    <input
                      type="radio"
                      className="mx-2"
                      name="groupType"
                      value="private"
                      onClick={() => setGroupType("private")}
                      defaultChecked={groupType === "private"}
                    />
                  </div>
                </div>

                {groupType === "private" && (
                  <div className="text-start mt-2">
                    <label
                      htmlFor="groupName"
                      className="text-start m-auto mb-1 text-lg"
                    >
                      Private Group Password :
                    </label>
                    <input
                      type="password"
                      className="p-2 w-full focus:outline-none border border-gray-400 rounded-lg mb-2"
                      placeholder="Group Password"
                      value={privatepwd}
                      onChange={(e) => setPrivatepwd(e.target.value)}
                    />
                  </div>
                )}

                {(filteredUsers.length > 0 || userList !== "") && (
                  <ul className="w-full bg-white rounded-lg shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] overflow-y-scroll h-40 mt-4">
                    {(filteredUsers.length > 0 ? filteredUsers : userList).map(
                      (user) => (
                        <li
                          className="flex items-center justify-start sm:pl-0 pr-2 bg-gray-100 hover:bg-gray-200 transition-all duration-300 cursor-pointer border-b-2"
                          key={user?._id}
                        >
                          <label
                            className="flex justify-left items-center px-5 py-[12px] mb-0 w-full"
                            htmlFor={`user${user?._id}`}
                          >
                            <div className="relative self-center mr-3 border-[1px] border-gray-500 rounded-full p-1">
                              <img
                                src={user?.hero_img}
                                className="w-12 h-12 flex items-center justify-center rounded-full border border-gray-500 font-bold text-uppercase bg-slate-300"
                                alt=""
                              />
                            </div>

                            <div className="flex flex-col w-3/4 items-start">
                              <div className="text-left text-base">
                                {user?.name}
                              </div>
                              {/* <p className="text-sm">
                                {user.email}
                              </p> */}
                            </div>
                          </label>
                          <input
                            type="checkbox"
                            id={`user${user?._id}`}
                            className="sm:mx-3 mx-2 p-1"
                            checked={selectedUserGroup.includes(user?._id)}
                            onChange={() => handleCheckboxChange(user?._id)}
                          />
                        </li>
                      )
                    )}
                  </ul>
                )}
              </div>
              {loading === true ? (
                <button className="flex items-center space-x-2 bg-blue-500 text-white px-4 py-2 rounded-md w-full text-center justify-center">
                  Loading...
                </button>
              ) : (
                <button
                  className="flex items-center space-x-2 bg-blue-500 text-white px-4 py-2 rounded-md w-full text-center justify-center mt-4"
                  onClick={createGroupe}
                >
                  Create
                </button>
              )}
            </div>
          </div>
        </>
      ) : null}

      {createGroupresult === true ? (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
          {/* <div className="bg-white p-6 rounded shadow-lg text-center flex justify-start items-center flex-col w-2/4 h-96"> */}
          <div className="bg-green-100 text-green-900 p-8 rounded-lg shadow-lg relative flex flex-col items-center justify-center">
            <button
              className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 absolute top-2 right-1"
              onClick={()=> handleStartChat()}
            >
              X
            </button>
            <h2 className="text-2xl font-bold my-4">
              Group Created Successfully
            </h2>
              <div
                href="/chats"
                className="bg-green-500 text-white px-4 py-2 rounded-md cursor-pointer"
                onClick={()=> handleStartChat()}
              >
                Start Chat
              </div>
          </div>
        </div>
      ) : null}

      {(isDesktopView || selectedChat) && (
        <>
          {isDesktopView === true ? (
            <div className="relative flex flex-col w-full sm:w-[502px] border-r-1 overflow-y-auto bg-[#F5F7FB] chatsList h-full">
              <div className="border border-1px-gray-500 bg-slate-200 rounded-lg">
                <div className="flex justify-between items-center border-b pb-3 bg-slate-200 p-3">
                  <div className="flex justify-start items-center">
                    <div className="">
                      <img
                        src={userData?.hero_img}
                        className="object-cover h-12 w-12 border-[1px] border-black rounded-full"
                        alt="chat profile"
                      />
                    </div>
                    <div className="mx-2">
                      <div className="text-lg font-semibold capitalize">
                        {User?.userData?.name}
                      </div>
                      <span className="text-gray-500 text-sm">Welcome</span>
                    </div>
                  </div>
                  <div className="flex text-lg relative -top-[4px] cursor-pointer" onClick={()=>{setShownotification(!shownotification)
                  }}>
                  <NotificationsNoneIcon sx={{ fontSize: 28 }}  className="w-8 h-8" />
                    {notificationData.length > 0 && (
                        <>
                        {notificationData.some((notification) => 
                              notification?.type === 'MESSAGE' ||
                              notification?.type === 'AUDIO_VIDEO_CALL' ||
                              notification?.type === 'CREATE_GROUP' ||
                              notification?.type === 'JOIN_PUBLIC_GROUP' ||
                              notification?.type === 'JOIN_PRIVATE_GROUP'
                              ) && (
                                <span className="w-[10px] h-[10px] bg-red-500 text-white absolute top-[3px] right-[3px] m-[1px] border-[1px] border-white inline-flex items-center justify-center rounded-full font-bold text-xs transition-all duration-700"></span>
                              )}
                        </>
                      )}
                    {/* {notificationData.length > 0 && (
                      <span className="w-[10px] h-[10px] bg-red-500 text-white absolute top-[3px] right-[3px] m-[1px] border-[1px] border-white inline-flex items-center justify-center rounded-full font-bold text-xs transition-all duration-700"></span>
                    )} */}
                  </div>
                  <button
                    className="flex items-center ml-2 text-green-600 border border-green-600 px-2 py-[6px] rounded-lg sm:px-5 sm:py-2 hover:bg-green-600 hover:text-white transition-all duration-500 ease-in-out transform"
                    onClick={handleGoBack}
                  >
                    <IoArrowBackSharp className="mr-2" /> Back
                  </button>
                </div>
                {shownotification ? (
                  <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50 h-full overflow-y-scroll">
                    <div className={`w-[65%] p-4 absolute top-0 ${shownotification ? 'slideBottom1' : ''}`}>
                      <button
                          onClick={() => setShownotification(false)}
                          className="text-xl font-semibold border border-1 px-2 rounded-lg py-0 bg-white hover:bg-slate-200 fixed right-4 -top-[14px]"
                        >
                          X
                      </button>
                      <div className={`bg-white px-8 pt-4 ${notificationData.length > 0 ? 'pb-8' : 'pb-4'} max-h-[80vh] h-auto overflow-y-auto rounded shadow w-full m-auto mb-4`}>
                      {notificationData && notificationData.length > 0 ? (
                          <>
                            {notificationData.some((notification) => 
                            notification?.type === 'MESSAGE' ||
                            notification?.type === 'AUDIO_VIDEO_CALL' ||
                            notification?.type === 'CREATE_GROUP' ||
                            notification?.type === 'JOIN_PUBLIC_GROUP' ||
                            notification?.type === 'JOIN_PRIVATE_GROUP'
                            ) ? (
                              <>
                                <div className="font-semibold text-lg pb-2 text-center">All Notifications</div>
                                {notificationData.map((notification, index) => ( notification.type ? (
                                  <>
                                    {(notification?.type === 'MESSAGE' && ((userData?._id !== notification?.userId) || (userData?._id === notification?.author))) && (
                                      <div
                                        key={notification?._id}
                                        id={`notification-${notification?._id}`}
                                        className={`flex items-center p-3 rounded-lg hover:bg-gray-100 cursor-pointer mb-2 bg-white border`}
                                      >
                                        <img
                                          className="w-10 h-10 rounded-full mr-4"
                                          src={notification?.userdata?.hero_img ||'/profile.png'}
                                          alt="Profile"
                                        />
                                        <div className="flex-1">
                                              <p className="text-sm text-gray-700">
                                                <Link to={`/profile/${notification?.userdata?._id}`} 
                                                  className="font-semibold text-blue-600 hover:underline capitalize"
                                                >
                                                  {notification?.userdata?.name }
                                                </Link>
                                                {" "} {userData?._id !== notification?.userId && userData?._id !== notification?.author
                                                        ? <>
                                                        {notification?.groups?.name && (
                                                          <>
                                                          sent a message in the 
                                                            <Link to={`/chats`} 
                                                              className="font-semibold text-blue-600 hover:underline ml-1"
                                                            >
                                                                {notification?.groups?.name?.toLowerCase()?.replace(/\b\w/g, char => char.toUpperCase())}
                                                            </Link> group.
                                                          </>
                                                        )}
                                                          </>
                                                        : `sent you a message.`}
                                                {/* <Link to={`/chats`} 
                                                  className="ml-1 font-semibold text-blue-600 hover:underline"
                                                  
                                                >
                                                  View Message
                                                </Link> */}
                                              </p>
                                          <p className="text-xs text-gray-500">
                                            {new Date(notification?.createdAt || notification?.user?.createdAt).toLocaleString()}
                                          </p> 
                                        </div>
                                      </div>
                                    )}
                                    {(notification?.type === 'AUDIO_VIDEO_CALL' && ((userData?._id !== notification?.userId) || (userData?._id === notification?.author))) && (
                                      <div
                                        key={notification?._id}
                                        id={`notification-${notification?._id}`}
                                        className={`flex items-center p-3 rounded-lg hover:bg-gray-100 cursor-pointer mb-2 bg-white border`}
                                      >
                                        <img
                                          className="w-10 h-10 rounded-full mr-4"
                                          src={notification?.userdata?.hero_img ||'/profile.png'}
                                          alt="Profile"
                                        />
                                        <div className="flex-1">
                                              <p className="text-sm text-gray-700">
                                                <Link to={`/profile/${notification?.userdata?._id}`} 
                                                  className="font-semibold text-blue-600 hover:underline capitalize"
                                                >
                                                  {notification?.userdata?.name } {" "}
                                                </Link>
                                                {notification?.message === 'individual_audio_call' ? (<>
                                                  called you (<span className="font-semibold">audio call</span>).
                                                </>) : notification?.message === 'individual_video_call' ? (<>
                                                  called you (<span className="font-semibold">video call</span>).
                                                </>) : notification?.message === 'group_audio_call' ? (<>
                                                  started a group audio call in 
                                                  {notification?.groups?.name && (
                                                    <>
                                                      <Link to={`/chats`} 
                                                        className="font-semibold text-blue-600 hover:underline ml-1"
                                                      >
                                                          {notification?.groups?.name?.toLowerCase()?.replace(/\b\w/g, char => char.toUpperCase())}
                                                      </Link> group.
                                                    </>
                                                  )}
                                                </>) : (<>
                                                  started a group video call in 
                                                  <>
                                                    <Link to={`/chats`} className="font-semibold text-blue-600 hover:underline ml-1" >
                                                        {notification?.groups?.name?.toLowerCase()?.replace(/\b\w/g, char => char.toUpperCase())}
                                                    </Link> group.
                                                  </>
                                                </>) }
                                              </p>
                                          <p className="text-xs text-gray-500">
                                            {new Date(notification?.createdAt || notification?.user?.createdAt).toLocaleString()}
                                          </p> 
                                        </div>
                                      </div>
                                    )}
                                    {(notification?.type === 'CREATE_GROUP' && ((userData?._id !== notification?.userId) || (userData?._id === notification?.author))) && (
                                      <div
                                        key={notification?._id}
                                        id={`notification-${notification?._id}`}
                                        className={`flex items-center p-3 rounded-lg hover:bg-gray-100 cursor-pointer mb-2 bg-white border`}
                                      >
                                        <img
                                          className="w-10 h-10 rounded-full mr-4"
                                          src={notification?.userdata?.hero_img ||'/profile.png'}
                                          alt="Profile"
                                        />
                                        <div className="flex-1">
                                              <p className="text-sm text-gray-700">
                                                <Link to={`/profile/${notification?.userdata?._id}`} 
                                                  className="font-semibold text-blue-600 hover:underline capitalize"
                                                >
                                                  {notification?.userdata?.name } {" "}
                                                </Link>
                                                  has created a new group 
                                                <Link to={`/chats`} className="font-semibold text-blue-600 hover:underline ml-1" >
                                                    {notification?.groups?.name?.toLowerCase()?.replace(/\b\w/g, char => char.toUpperCase())}
                                                </Link> and added you as member.
                                              </p>
                                          <p className="text-xs text-gray-500">
                                            {new Date(notification?.createdAt || notification?.user?.createdAt).toLocaleString()}
                                          </p> 
                                        </div>
                                      </div>
                                    )}
                                    {(notification?.type === 'JOIN_PUBLIC_GROUP' && ((userData?._id === notification?.userId) || (userData?._id === notification?.author))) && (
                                      <div
                                        key={notification?._id}
                                        id={`notification-${notification?._id}`}
                                        className={`flex items-center p-3 rounded-lg hover:bg-gray-100 cursor-pointer mb-2 bg-white border`}
                                      >
                                        <img
                                          className="w-10 h-10 rounded-full mr-4"
                                          src={userData?._id === notification?.userId ? notification?.authordata?.hero_img : notification?.userdata?.hero_img  ||'/profile.png'}
                                          alt="Profile"
                                        />
                                        <div className="flex-1">
                                          <p className="text-sm text-gray-700">
                                              {" "} {userData?._id === notification?.userId
                                              ? <>
                                              <Link to={`/profile/${notification?.authordata?._id}`} 
                                                className="font-semibold text-blue-600 hover:underline capitalize"
                                              >
                                                {notification?.authordata?.name }
                                              </Link>
                                              {" "} sent you a message.
                                                </>
                                              : (
                                                <>
                                                    <Link to={`/profile/${notification?.authordata?._id}`} 
                                                      className="font-semibold text-blue-600 hover:underline capitalize"
                                                    >
                                                      {notification?.userdata?.name }
                                                    </Link>
                                                    {" "} has joined the {" "}
                                                    <Link to={`/profile/${notification?.authordata?._id}`} 
                                                      className="font-semibold text-blue-600 hover:underline capitalize"
                                                    >
                                                      {notification?.groups?.name }
                                                    </Link> group.
                                                  </>
                                              )}
                                            </p>
                                            <p className="text-xs text-gray-500">
                                              {new Date(notification?.createdAt || notification?.user?.createdAt).toLocaleString()}
                                            </p> 
                                        </div>
                                      </div>
                                    )}
                                    {(notification?.type === 'JOIN_PRIVATE_GROUP' && ((userData?._id === notification?.userId) || (userData?._id === notification?.author))) && (
                                      <div
                                        key={notification?._id}
                                        id={`notification-${notification?._id}`}
                                        className={`flex items-center p-3 rounded-lg hover:bg-gray-100 cursor-pointer mb-2 bg-white border`}
                                      >
                                        <img
                                          className="w-10 h-10 rounded-full mr-4"
                                          src={userData?._id === notification?.userId ? notification?.authordata?.hero_img : notification?.userdata?.hero_img  ||'/profile.png'}
                                          alt="Profile"
                                        />
                                        <div className="flex-1">
                                          <p className="text-sm text-gray-700">
                                            {userData?._id === notification?.userId
                                              ? <>
                                              <Link to={`/profile/${notification?.authordata?._id}`} 
                                                className="font-semibold text-blue-600 hover:underline capitalize"
                                              >
                                                {notification?.authordata?.name }
                                              </Link>
                                              {" "} sent you a message.
                                                </>
                                              : (
                                                <>
                                                    <Link to={`/profile/${notification?.authordata?._id}`} 
                                                      className="font-semibold text-blue-600 hover:underline capitalize"
                                                    >
                                                      {notification?.userdata?.name }
                                                    </Link>
                                                    {" "} has joined the {" "}
                                                    <Link to={`/profile/${notification?.authordata?._id}`} 
                                                      className="font-semibold text-blue-600 hover:underline capitalize"
                                                    >
                                                      {notification?.groups?.name }
                                                    </Link> group.
                                                  </>
                                              )}
                                            </p>
                                            <p className="text-xs text-gray-500">
                                              {new Date(notification?.createdAt || notification?.user?.createdAt).toLocaleString()}
                                            </p> 
                                        </div>
                                      </div>
                                    )}
                                  </>
                                  ) : null
                                ))}
                              </>
                            ) : (
                              <div className="text-center text-xl text-gray-600 py-[10px] -mb-2 mt-1">No Notifications Found</div>
                            )}
                          </>
                        ) : (
                          <div className="text-center text-xl text-gray-600 py-4">
                            No Notifications Found
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
                
                {/* <div className="w-full z-[9999]">
                </div> */}
                <div className="p-3">
                  <input
                    type="text"
                    placeholder="Search Chats"
                    value={searchTerm}
                    className="py-2 px-2 rounded-lg w-full border border-gray-400"
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>

                <div className="flex justify-between items-center px-3 pb-3 relative">
                  <button
                    className="flex items-center space-x-2 bg-blue-800 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-all duration-300"
                    onClick={() => setCreateGroupPopup(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="h-5 w-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                      />
                    </svg>
                    Create Group
                  </button>

                  <button
                    className="flex items-center space-x-2 bg-green-700 text-white px-4 py-2 rounded-md font-semibold hover:bg-green-600 transition-all duration-300"
                    onClick={() => setNewChatPopup(true)}
                  >
                    <svg
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25px"
                      stroke="#fff"
                      strokeWidth="0.336"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M22 12C22 17.5228 17.5228 22 12 22C10.4003 22 8.88837 21.6244 7.54753 20.9565C7.19121 20.7791 6.78393 20.72 6.39939 20.8229L4.17335 21.4185C3.20701 21.677 2.32295 20.793 2.58151 19.8267L3.17712 17.6006C3.28001 17.2161 3.22094 16.8088 3.04346 16.4525C2.37562 15.1116 2 13.5997 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM15.5303 9.46967C15.8232 9.76256 15.8232 10.2374 15.5303 10.5303L11.5303 14.5303C11.2417 14.819 10.7751 14.8238 10.4806 14.541L8.4806 12.621C8.18179 12.3342 8.1721 11.8594 8.45896 11.5606C8.74582 11.2618 9.22059 11.2521 9.5194 11.539L10.9893 12.9501L14.4697 9.46967C14.7626 9.17678 15.2374 9.17678 15.5303 9.46967Z"
                          fill="#fff"
                        ></path>
                      </g>
                    </svg>
                    New Chat
                  </button>
                </div>
              </div>
              <div className={`max-h-[90vh] overflow-y-auto ${isFetched ? 'flex items-center justify-center mt-16' : ''}`}>
              {isFetched ? (
                <div className="flex justify-center items-center min-h-[180px]">
                <CircularProgress />
              </div>
              ) : (
                <>
                {chatType === "chat" &&
                  sortedCombinedChatList.map((user,index) => {
                    const isGroup = !!user.members; // Check if it's a group based on presence of members array

                    return (
                      <div
                        className={`flex px-5 py-[15px] hover:bg-[#E6EBF5] transition-all duration-300 cursor-pointer border-b-[1px] ${sortedCombinedChatList.length-1 === index && ' mb-12'}`}
                        onClick={() => {
                          if (isGroup) {
                            openChat(
                              user?._id,
                              user?.name,
                              true,
                              user?.members?.length,
                              user?.members,
                              user?.creator
                            );
                            setIsGroup(true);
                          } else {
                            openChat(user?._id, user?.name, user?.hero_img);
                            setIsGroup(false);
                          }
                          setIsCheckPrivateGroupMember(true);
                        }}
                        key={user?._id}
                      >
                        {/* Render different UI for group vs individual chat */}
                        {isGroup ? (
                          <>
                            <div className="relative self-center mr-3 border-[1px] border-gray-500 rounded-full p-1">
                              <p className="w-12 h-12 flex items-center justify-center rounded-full border border-gray-500 p-1 font-bold text-uppercase bg-slate-300">
                                {user?.name?.charAt(0).toUpperCase()}
                              </p>
                              <span className="absolute w-2.5 h-2.5 bg-yellow-500 border-2 border-white rounded-full top-9 ltr:right-1 rtl:left-1 dark:border-zinc-600"></span>
                            </div>
                            <div className="flex-grow overflow-hidden">
                              <h5 className="mb-1 font-semibold text-black capitalize">
                                {user?.name}
                              </h5>
                              <p className="mb-0 text-gray-500 dark:text-gray-300 text-[14px]">
                                {user?.lastMessage?.content}
                              </p>
                            </div>
                            
                            <div className="text-gray-500 text-xs dark:text-gray-300 pt-1">
                              {user?.lastMessage != null
                                ? GetDate(user?.lastMessage?.createdAt)
                                : GetDate(user?.updatedAt)}
                                
                                {user?.seenCount? <p className="bg-[#17c607] text-white rounded-full p-2 w-[22px] h-[22px] flex items-center justify-center">{user?.seenCount}</p>:""}
                                <br />
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="relative self-center mr-3 border-[1px] border-gray-500 rounded-full p-1 bg-white">
                              <img
                                src={user.hero_img}
                                className="rounded-full w-12 h-12 border-[1px] border-gray-500 bg-gray-300"
                                alt=""
                              />
                              <span className="absolute w-2.5 h-2.5 bg-green-500 border-2 border-white rounded-full top-9 ltr:right-1 rtl:left-1 dark:border-zinc-600"></span>
                            </div>

                            <div className="flex-grow overflow-hidden">
                              <h5 className="mb-1 font-semibold text-black capitalize ">
                                {user?.name}
                              </h5>
                              <p className="mb-0 text-gray-500 dark:text-gray-300 text-[14px] block w-40 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                {user?.lastMessage?.content}
                              </p>
                            </div>
                            <div className="text-gray-500 text-xs dark:text-gray-300 pt-1">
                              <p>{GetDate(user?.lastMessage?.createdAt)}</p>
                              {user?.seenCount? <p className="bg-[#17c607] text-white rounded-full p-2 w-[22px] h-[22px] flex items-center justify-center">{user?.seenCount}</p>:""}

                            </div>
                          </>
                        )}
                      </div>
                    );
                  })}
                {chatType === "private-group" &&
                getAllPrivateGroup &&
                Array.isArray(getAllPrivateGroup) &&
                getAllPrivateGroup.length > 0
                  ? getAllPrivateGroup.map((user, index) => {
                      const checkPrivateGroupMember = user.members.some(
                        (member) => member._id === userData._id
                      );
                      return (
                        <div
                          className={`flex px-5 py-[15px] hover:bg-[#E6EBF5] transition-all duration-300 cursor-pointer border-b-[1px] ${getAllPrivateGroup.length-1 === index && ' mb-12'}`}
                          onClick={() => {
                            openChat(
                              user?._id,
                              user?.name,
                              "",
                              user?.members.length,
                              setIsCheckPrivateGroupMember(
                                checkPrivateGroupMember
                              ),
                              user?.members,
                              user?.creator
                            );
                            setIsGroup(true);
                            if(!isPrivateGroupMember[index]){
                              setIsPrivateGroup(true);
                              handleBackToChatList();
                            }else{
                              setPrivateGroupPop(false);
                            }
                          }}
                          key={user?._id}
                        >
                          <div className={`relative self-center mr-3 border-[1px] border-gray-500 rounded-full p-1 ${isPrivateGroupMember[index]}`}>
                            <p className="w-12 h-12 flex items-center justify-center rounded-full border border-gray-500 p-1 font-bold text-uppercase bg-slate-300 capitalize">
                              {user?.name?.charAt(0).toUpperCase()}
                            </p>
                            <span className="absolute w-2.5 h-2.5 bg-yellow-500 border-2 border-white rounded-full top-9 ltr:right-1 rtl:left-1 dark:border-zinc-600"></span>
                          </div>

                          <div className="flex-grow overflow-hidden">
                            <h5 className="mb-1 font-semibold text-black capitalize">
                              {user?.name}
                            </h5>
                            <p className="mb-0 text-gray-500 truncate dark:text-gray-300 text-14">
                              {user?.lastMessage?.content}
                            </p>
                          </div>
                          <div className="text-gray-500 text-xs dark:text-gray-300 pt-1 relative">
                            <div className="mb-0 text-gray-500 dark:text-gray-300 text-[14px] ">
                              {user?.lastMessage != null
                                ? GetDate(user?.lastMessage?.createdAt)
                                : GetDate(user?.updatedAt)}
                            </div>
                            {!isPrivateGroupMember[index] && (
                              <div className="border border-blue-600 rounded-lg absolute top-[28px] right-0 w-32 sm:w-28 text-center p-[2px] hover:border-green-600">
                                <button
                                  className="text-xs px-1 hover:text-green-700 text-blue-600"
                                  onClick={(e) => {
                                    e.stopPropagation(); 
                                    setPrivateGroupPop(true);
                                    handleJoinGroup(user);
                                  }}
                                >
                                  Join Private Group
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })
                  : null}

                {chatType === "public-group"
                  ? getAllPublicGroup &&
                    getAllPublicGroup.map((user, index) => (
                      <div
                        className={`flex px-5 py-[15px] hover:bg-[#E6EBF5] transition-all duration-300 cursor-pointer border-b-[1px] ${getAllPublicGroup.length-1 === index && ' mb-12'}`}
                        onClick={() => {
                          openChat(
                            user?._id,
                            user?.name,
                            "",
                            user?.members.length
                          );
                          setIsGroup(true);
                          setIsCheckPrivateGroupMember(true);
                        }}
                        key={user?._id}
                      >
                        <div className="relative self-center mr-3 border-[1px] border-gray-500 rounded-full p-1">
                          <p className="w-12 h-12 flex items-center justify-center rounded-full border border-gray-500 p-1 font-bold text-uppercase bg-slate-300 capitalize">
                            {user?.name?.charAt(0).toUpperCase()}
                          </p>
                          <span className="absolute w-2.5 h-2.5 bg-yellow-500 border-2 border-white rounded-full top-9 ltr:right-1 rtl:left-1 dark:border-zinc-600"></span>
                        </div>

                        <div className="flex-grow overflow-hidden">
                          <h5 className="mb-1 font-semibold text-black capitalize">
                            {user?.name}
                          </h5>
                          {/* <p className="mb-0 text-gray-500 truncate dark:text-gray-300 text-14">{user?.userHandle}</p> */}
                          <p className="mb-0 text-gray-500 dark:text-gray-300 text-[14px]">
                            {user?.lastMessage?.content}
                          </p>
                        </div>
                        <div className="text-gray-500 text-xs dark:text-gray-300 pt-1 relative">
                          {user?.lastMessage != null
                            ? GetDate(user?.lastMessage?.createdAt)
                            : GetDate(user?.updatedAt)}
                          {!isPublicGroupMember[index] && (
                            <div className="border border-blue-600 rounded-lg absolute top-[28px] right-0 w-32 sm:w-28 text-center p-[2px] hover:border-green-600">
                              <button
                                className="text-xs px-1 hover:text-green-700 text-blue-600 "
                                onClick={() => handleJoinGroup(user)}
                              >
                                Join Public Group
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    ))
                  : null}
                </>
              )}
                
              </div>
              <div className="px-3 py-2 bg-gray-300 w-full absolute bottom-0">
                <div className="w-3/4 flex justify-between items-center m-auto ">
                  <div className={`bg px-2 py-1 rounded-lg border transition-all duration-300 ${chatType === "chat" ? "bg-green-600 text-white border-green-500" : "bg-white border-slate-400"} cursor-pointer`} onClick={() => setChatType("chat")} >
                    <button>
                      <RiUser3Line className="text-3xl" />
                    </button>
                  </div>
                  <div className={`px-2 py-1 rounded-lg border transition-all duration-300 ${chatType === "public-group" ? "bg-green-600 text-white border-green-500" : "bg-white border-slate-400"} cursor-pointer`} onClick={() => setChatType("public-group")} >
                    <button>
                      <GrGroup className="text-3xl" />
                    </button>
                  </div>
                  <div className={`px-2 py-1 rounded-lg border transition-all duration-300 ${ chatType === "private-group" ? "bg-green-600 text-white border-green-500" : "bg-white border-slate-400" } cursor-pointer`} onClick={() => setChatType("private-group")} >
                    <button>
                      <FaUserLock className="text-3xl" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </>
      )}

      {selectedChat ? (
        <>
          {/* <button onClick={handleBackToChatList}>handleBackToChatList</button> */}
          <Chats
            data={{
              receiverId: selectedChat,
              CallContext: CallContext,
              AudioCallContext: AudioCallContext,
              GroupCallContext: GroupCallContext,
              NewGroupCallContext: NewGroupCallContext,
              senderId,
              senderName,
              receiverName,
              handleBackToChatList: handleBackToChatList,
              selectedChatImage: selectedChatImage,
              isGroup: isGroup,
              groupMemberCount: groupMemberCount,
              groupMembers: groupMembers,
              isCheckPrivateGroupMember: isCheckPrivateGroupMember,
              creator: creator,
            }}
          />
        </>
      ) : (
        <>
          {isDesktopView === false ? (
            <div className="flex flex-col w-full md:w-2/5 border-r-2 overflow-y-auto transition-all duration-300 cursor-pointer h-full">
              <div className="border border-[1px]-gray-600 rounded-b-lg shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] hover:bg-gray-300 transition-all duration-300 cursor-pointer">
                <div className="flex justify-between items-center border-b pb-3 p-3">
                  <div className="flex justify-start items-center">
                    <div className="">
                      <img
                        src={userData?.hero_img}
                        className="object-cover h-12 w-12 border-[1px] border-black rounded-full"
                        alt="chat profile"
                      />
                    </div>
                    <div className="mx-2">
                      <div className="text-lg font-semibold capitalize">
                        {User?.userData?.name}
                      </div>
                      <span className="text-gray-500 text-sm">Welcome </span>
                    </div>
                  </div>
                  <div className="flex text-lg relative -top-[4px] cursor-pointer" onClick={()=>{setShownotification(!shownotification) }}>
                    <NotificationsNoneIcon sx={{ fontSize: 28 }}  className="w-8 h-8" />

                    {notificationData.length > 0 && (
                      <>
                      {notificationData.some((notification) => 
                            notification?.type === 'MESSAGE' ||
                            notification?.type === 'AUDIO_VIDEO_CALL' ||
                            notification?.type === 'CREATE_GROUP' ||
                            notification?.type === 'JOIN_PUBLIC_GROUP' ||
                            notification?.type === 'JOIN_PRIVATE_GROUP'
                            ) && (
                              <span className="w-[10px] h-[10px] bg-red-500 text-white absolute top-[3px] right-[3px] m-[1px] border-[1px] border-white inline-flex items-center justify-center rounded-full font-bold text-xs transition-all duration-700"></span>
                            )}
                      </>
                    )}
                    {/* {notificationData.length > 0 && (
                      <span className="w-[10px] h-[10px] bg-red-500 text-white absolute top-[3px] right-[3px] m-[1px] border-[1px] border-white inline-flex items-center justify-center rounded-full font-bold text-xs transition-all duration-700"></span>
                    )} */}
                  </div>
                  <button
                    className="flex items-center ml-2 text-green-600 border border-green-600 px-2 py-[6px] rounded-lg sm:px-5 sm:py-2 hover:bg-green-600 hover:text-white transition-all duration-500 ease-in-out transform"
                    onClick={handleGoBack}
                  >
                    <IoArrowBackSharp className="mr-2" /> Back
                  </button>
                </div>
                {shownotification ? (
                  <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50 h-full overflow-y-scroll">
                    <div className={`w-full p-4 absolute top-0 left-0 ${shownotification ? 'slideBottom1' : ''}`}>
                        <button
                            onClick={() => setShownotification(false)}
                            className="text-xl font-semibold border border-1 px-2 rounded-lg py-0 bg-white hover:bg-slate-200 fixed right-4 -top-[14px]"
                          >
                            X
                        </button>
                      <div className={`bg-white px-4 pt-6 pb-4 max-h-[80vh] h-auto overflow-y-auto rounded shadow w-full m-auto mb-4`}>
                      {notificationData && notificationData.length > 0 ? (
                        <>
                          {notificationData.some((notification) => 
                          notification?.type === 'MESSAGE' ||
                          notification?.type === 'AUDIO_VIDEO_CALL' ||
                          notification?.type === 'CREATE_GROUP' ||
                          notification?.type === 'JOIN_PUBLIC_GROUP' ||
                          notification?.type === 'JOIN_PRIVATE_GROUP'
                          ) ? (
                            <>
                              <div className="font-semibold text-lg pb-2 text-center">All Notifications</div>
                              {notificationData.map((notification, index) => ( notification.type ? (
                                <>
                                  {(notification?.type === 'MESSAGE' && ((userData?._id !== notification?.userId) || (userData?._id === notification?.author))) && (
                                    <div
                                      key={notification?._id}
                                      id={`notification-${notification?._id}`}
                                      className={`flex items-center p-3 rounded-lg hover:bg-gray-100 cursor-pointer mb-2 bg-white border`}
                                    >
                                      <img
                                        className="w-10 h-10 rounded-full mr-4"
                                        src={notification?.userdata?.hero_img ||'/profile.png'}
                                        alt="Profile"
                                      />
                                      <div className="flex-1">
                                            <p className="text-sm text-gray-700">
                                              <Link to={`/profile/${notification?.userdata?._id}`} 
                                                className="font-semibold text-blue-600 hover:underline capitalize"
                                              >
                                                {notification?.userdata?.name }
                                              </Link>
                                              {" "} {userData?._id !== notification?.userId && userData?._id !== notification?.author
                                                      ? <>
                                                      {notification?.groups?.name && (
                                                        <>
                                                        sent a message in the 
                                                          <Link to={`/chats`} 
                                                            className="font-semibold text-blue-600 hover:underline ml-1"
                                                          >
                                                              {notification?.groups?.name?.toLowerCase()?.replace(/\b\w/g, char => char.toUpperCase())}
                                                          </Link> group.
                                                        </>
                                                      )}
                                                        </>
                                                      : `sent you a message.`}
                                              {/* <Link to={`/chats`} 
                                                className="ml-1 font-semibold text-blue-600 hover:underline"
                                                
                                              >
                                                View Message
                                              </Link> */}
                                            </p>
                                        <p className="text-xs text-gray-500">
                                          {new Date(notification?.createdAt || notification?.user?.createdAt).toLocaleString()}
                                        </p> 
                                      </div>
                                    </div>
                                  )}
                                  {(notification?.type === 'AUDIO_VIDEO_CALL' && ((userData?._id !== notification?.userId) || (userData?._id === notification?.author))) && (
                                    <div
                                      key={notification?._id}
                                      id={`notification-${notification?._id}`}
                                      className={`flex items-center p-3 rounded-lg hover:bg-gray-100 cursor-pointer mb-2 bg-white border`}
                                    >
                                      <img
                                        className="w-10 h-10 rounded-full mr-4"
                                        src={notification?.userdata?.hero_img ||'/profile.png'}
                                        alt="Profile"
                                      />
                                      <div className="flex-1">
                                            <p className="text-sm text-gray-700">
                                              <Link to={`/profile/${notification?.userdata?._id}`} 
                                                className="font-semibold text-blue-600 hover:underline capitalize"
                                              >
                                                {notification?.userdata?.name } {" "}
                                              </Link>
                                              {notification?.message === 'individual_audio_call' ? (<>
                                                called you (<span className="font-semibold">audio call</span>).
                                              </>) : notification?.message === 'individual_video_call' ? (<>
                                                called you (<span className="font-semibold">video call</span>).
                                              </>) : notification?.message === 'group_audio_call' ? (<>
                                                started a group audio call in 
                                                {notification?.groups?.name && (
                                                  <>
                                                    <Link to={`/chats`} 
                                                      className="font-semibold text-blue-600 hover:underline ml-1"
                                                    >
                                                        {notification?.groups?.name?.toLowerCase()?.replace(/\b\w/g, char => char.toUpperCase())}
                                                    </Link> group.
                                                  </>
                                                )}
                                              </>) : (<>
                                                started a group video call in 
                                                <>
                                                  <Link to={`/chats`} className="font-semibold text-blue-600 hover:underline ml-1" >
                                                      {notification?.groups?.name?.toLowerCase()?.replace(/\b\w/g, char => char.toUpperCase())}
                                                  </Link> group.
                                                </>
                                              </>) }
                                            </p>
                                        <p className="text-xs text-gray-500">
                                          {new Date(notification?.createdAt || notification?.user?.createdAt).toLocaleString()}
                                        </p> 
                                      </div>
                                    </div>
                                  )}
                                  {(notification?.type === 'CREATE_GROUP' && ((userData?._id !== notification?.userId) || (userData?._id === notification?.author))) && (
                                    <div
                                      key={notification?._id}
                                      id={`notification-${notification?._id}`}
                                      className={`flex items-center p-3 rounded-lg hover:bg-gray-100 cursor-pointer mb-2 bg-white border`}
                                    >
                                      <img
                                        className="w-10 h-10 rounded-full mr-4"
                                        src={notification?.userdata?.hero_img ||'/profile.png'}
                                        alt="Profile"
                                      />
                                      <div className="flex-1">
                                            <p className="text-sm text-gray-700">
                                              <Link to={`/profile/${notification?.userdata?._id}`} 
                                                className="font-semibold text-blue-600 hover:underline capitalize"
                                              >
                                                {notification?.userdata?.name } {" "}
                                              </Link>
                                                has created a new group 
                                              <Link to={`/chats`} className="font-semibold text-blue-600 hover:underline ml-1" >
                                                  {notification?.groups?.name?.toLowerCase()?.replace(/\b\w/g, char => char.toUpperCase())}
                                              </Link> and added you as member.
                                            </p>
                                        <p className="text-xs text-gray-500">
                                          {new Date(notification?.createdAt || notification?.user?.createdAt).toLocaleString()}
                                        </p> 
                                      </div>
                                    </div>
                                  )}
                                  {(notification?.type === 'JOIN_PUBLIC_GROUP' && ((userData?._id === notification?.userId) || (userData?._id === notification?.author))) && (
                                    <div
                                      key={notification?._id}
                                      id={`notification-${notification?._id}`}
                                      className={`flex items-center p-3 rounded-lg hover:bg-gray-100 cursor-pointer mb-2 bg-white border`}
                                    >
                                      <img
                                        className="w-10 h-10 rounded-full mr-4"
                                        src={notification?.userdata?.hero_img ||'/profile.png'}
                                        alt="Profile"
                                      />
                                      <div className="flex-1">
                                        <p className="text-sm text-gray-700">
                                            {" "} {userData?._id === notification?.userId
                                            ? <>
                                            <Link to={`/profile/${notification?.authordata?._id}`} 
                                              className="font-semibold text-blue-600 hover:underline capitalize"
                                            >
                                              {notification?.authordata?.name }
                                            </Link>
                                            {" "} sent you a message.
                                              </>
                                            : (
                                              <>
                                                  <Link to={`/profile/${notification?.authordata?._id}`} 
                                                    className="font-semibold text-blue-600 hover:underline capitalize"
                                                  >
                                                    {notification?.userdata?.name }
                                                  </Link>
                                                  {" "} has joined the {" "}
                                                  <Link to={`/profile/${notification?.authordata?._id}`} 
                                                    className="font-semibold text-blue-600 hover:underline capitalize"
                                                  >
                                                    {notification?.groups?.name }
                                                  </Link> group.
                                                </>
                                            )}
                                          </p>
                                          <p className="text-xs text-gray-500">
                                            {new Date(notification?.createdAt || notification?.user?.createdAt).toLocaleString()}
                                          </p> 
                                      </div>
                                    </div>
                                  )}
                                  {(notification?.type === 'JOIN_PRIVATE_GROUP' && ((userData?._id === notification?.userId) || (userData?._id === notification?.author))) && (
                                    <div
                                      key={notification?._id}
                                      id={`notification-${notification?._id}`}
                                      className={`flex items-center p-3 rounded-lg hover:bg-gray-100 cursor-pointer mb-2 bg-white border`}
                                    >
                                      <img
                                        className="w-10 h-10 rounded-full mr-4"
                                        src={notification?.userdata?.hero_img ||'/profile.png'}
                                        alt="Profile"
                                      />
                                      <div className="flex-1">
                                        <p className="text-sm text-gray-700">
                                          {userData?._id === notification?.userId
                                            ? <>
                                            <Link to={`/profile/${notification?.authordata?._id}`} 
                                              className="font-semibold text-blue-600 hover:underline capitalize"
                                            >
                                              {notification?.authordata?.name }
                                            </Link>
                                            {" "} sent you a message.
                                              </>
                                            : (
                                              <>
                                                  <Link to={`/profile/${notification?.authordata?._id}`} 
                                                    className="font-semibold text-blue-600 hover:underline capitalize"
                                                  >
                                                    {notification?.userdata?.name }
                                                  </Link>
                                                  {" "} has joined the {" "}
                                                  <Link to={`/profile/${notification?.authordata?._id}`} 
                                                    className="font-semibold text-blue-600 hover:underline capitalize"
                                                  >
                                                    {notification?.groups?.name }
                                                  </Link> group.
                                                </>
                                            )}
                                          </p>
                                          <p className="text-xs text-gray-500">
                                            {new Date(notification?.createdAt || notification?.user?.createdAt).toLocaleString()}
                                          </p> 
                                      </div>
                                    </div>
                                  )}
                                </>
                                ) : null
                              ))}
                            </>
                          ) : (
                            <div className="text-center text-xl text-gray-600 py-[10px] -mb-2 mt-1">No Notifications Found</div>
                          )}
                        </>
                        ) : (
                          <div className="text-center text-xl text-gray-600 py-4">
                            No Notifications Found
                          </div>
                        )}
                      </div>
                      
                    </div>
                  </div>
                ) : null}
                <div className="p-3">
                  <input
                    type="text"
                    placeholder="Search Chats"
                    className="py-2 px-2 rounded-lg w-full border border-gray-400"
                  />
                </div>

                <div className="flex justify-between items-center px-3 pb-3">
                  <button
                    className="flex items-center space-x-2 bg-blue-500 text-white px-4 py-2 rounded-md"
                    onClick={() => setCreateGroupPopup(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="h-5 w-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                      />
                    </svg>
                    Create Group
                  </button>

                  <button
                    className="flex items-center space-x-2 bg-green-500 text-white px-4 py-2 rounded-md"
                    onClick={() => setNewChatPopup(true)}
                  >
                    <svg
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25px"
                      stroke="#fff"
                      strokeWidth="0.336"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M22 12C22 17.5228 17.5228 22 12 22C10.4003 22 8.88837 21.6244 7.54753 20.9565C7.19121 20.7791 6.78393 20.72 6.39939 20.8229L4.17335 21.4185C3.20701 21.677 2.32295 20.793 2.58151 19.8267L3.17712 17.6006C3.28001 17.2161 3.22094 16.8088 3.04346 16.4525C2.37562 15.1116 2 13.5997 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM15.5303 9.46967C15.8232 9.76256 15.8232 10.2374 15.5303 10.5303L11.5303 14.5303C11.2417 14.819 10.7751 14.8238 10.4806 14.541L8.4806 12.621C8.18179 12.3342 8.1721 11.8594 8.45896 11.5606C8.74582 11.2618 9.22059 11.2521 9.5194 11.539L10.9893 12.9501L14.4697 9.46967C14.7626 9.17678 15.2374 9.17678 15.5303 9.46967Z"
                          fill="#fff"
                        ></path>
                      </g>
                    </svg>
                    New Chat
                  </button>
                </div>
              </div>
              <div className={`overflow-y-auto ${isFetched ? 'flex items-center justify-center h-[55vh]' : 'h-full'}`}>
              {isFetched ? (
                <div className="flex justify-center items-center min-h-[180px]">
                <CircularProgress />
              </div>
              ) : (
                <>
                  {chatType === "chat" &&
                    sortedCombinedChatList.map((user,index) => {
                      const isGroup = !!user.members; 

                      return (
                        <div
                          className={`flex px-5 py-[15px] hover:bg-[#E6EBF5] transition-all duration-300 cursor-pointer border-b-[1px] ${sortedCombinedChatList.length-1 === index && ' mb-28'}`}
                          onClick={() => {
                            if (isGroup) {
                              openChat(
                                user?._id,
                                user?.name,
                                true,
                                user?.members?.length,
                                user?.members,
                                user?.creator
                              );
                              setIsGroup(true);
                            } else {
                              openChat(user?._id, user?.name, user?.hero_img);
                              setIsGroup(false);
                            }
                            setIsCheckPrivateGroupMember(true);
                          }}
                          key={user?._id}
                        >
                          {/* Render different UI for group vs individual chat */}
                          {isGroup ? (
                            <>
                              <div className="relative self-center mr-3 border-[1px] border-gray-500 rounded-full p-1">
                                <p className="w-12 h-12 flex items-center justify-center rounded-full border border-gray-500 p-1 font-bold text-uppercase bg-slate-300">
                                  {user?.name?.charAt(0).toUpperCase()}
                                </p>
                                <span className="absolute w-2.5 h-2.5 bg-yellow-500 border-2 border-white rounded-full top-9 ltr:right-1 rtl:left-1 dark:border-zinc-600"></span>
                              </div>
                              <div className="flex-grow overflow-hidden">
                                <h5 className="mb-1 font-semibold text-black capitalize">
                                  {user?.name}
                                </h5>
                                <p className="mb-0 text-gray-500 dark:text-gray-300 text-[14px]">
                                  {user?.lastMessage?.content}
                                </p>
                              </div>
                              <div className="text-gray-500 text-xs dark:text-gray-300 pt-1">
                                {user?.lastMessage != null
                                  ? GetDate(user?.lastMessage?.createdAt)
                                  : GetDate(user?.updatedAt)}
                                {user?.seenCount? <p className="bg-[#17c607] text-white rounded-full p-2 w-[22px] h-[22px] flex items-center justify-center">{user?.seenCount}</p>:""}

                              </div>
                            </>
                          ) : (
                            <>
                              <div className="relative self-center mr-3 border-[1px] border-gray-500 rounded-full p-1 bg-white">
                                <img
                                  src={user.hero_img}
                                  className="rounded-full w-12 h-12 border-[1px] border-gray-500 bg-gray-300"
                                  alt=""
                                />
                                <span className="absolute w-2.5 h-2.5 bg-green-500 border-2 border-white rounded-full top-9 ltr:right-1 rtl:left-1 dark:border-zinc-600"></span>
                              </div>

                              <div className="flex-grow overflow-hidden">
                                <h5 className="mb-1 font-semibold text-black capitalize ">
                                  {user?.name}
                                </h5>
                                <p className="mb-0 text-gray-500 dark:text-gray-300 text-[14px] block w-40 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                  {user?.lastMessage?.content}
                                </p>
                              </div>
                              <div className="text-gray-500 text-xs dark:text-gray-300 pt-1">
                                {GetDate(user?.lastMessage?.createdAt)}
                                {user?.seenCount? <p className="bg-[#17c607] text-white rounded-full p-2 w-[22px] h-[22px] flex items-center justify-center">{user?.seenCount}</p>:""}

                              </div>
                            </>
                          )}
                        </div>
                      );
                    })}

                  {chatType === "private-group"
                    ? getAllPrivateGroup &&
                      getAllPrivateGroup.map((user, index) => (
                        <div
                          className={`flex px-5 py-[15px] hover:bg-[#E6EBF5] transition-all duration-300 cursor-pointer border-b-[1px] ${getAllPrivateGroup.length-1 === index && ' mb-28'}`}
                          onClick={() => {
                            setIsGroup(true);
                            if(!isPrivateGroupMember[index]){
                                setIsPrivateGroup(true);
                                handleBackToChatList();
                              }else{
                                openChat(user?._id, user?.name, user?.members.length);
                                setPrivateGroupPop(false);
                              }
                          }}
                          key={user?._id}
                        >
                          <div className="relative self-center mr-3 border-[1px] border-gray-500 rounded-full p-1">
                            <p className="w-12 h-12 flex items-center justify-center rounded-full border border-gray-500 p-1 font-bold text-uppercase bg-slate-300 capitalize">
                              {user?.name?.charAt(0).toUpperCase()}
                            </p>
                            <span className="absolute w-2.5 h-2.5 bg-yellow-500 border-2 border-white rounded-full top-9 ltr:right-1 rtl:left-1 dark:border-zinc-600"></span>
                          </div>

                          <div className="flex-grow overflow-hidden">
                            <h5 className="mb-1 font-semibold text-black capitalize">
                              {user?.name}
                            </h5>
                            {/* <p className="mb-0 text-gray-500 truncate dark:text-gray-300 text-14">{user?.userHandle}</p> */}
                            <p className="mb-0 text-gray-500 dark:text-gray-300 text-[14px]">
                              {user?.lastMessage?.content}
                            </p>
                          </div>
                          <div className="text-gray-500 text-xs dark:text-gray-300 pt-1 relative">
                            {user?.lastMessage != null
                              ? GetDate(user?.lastMessage?.createdAt)
                              : GetDate(user?.updatedAt)}

                            {!isPrivateGroupMember[index] && (
                              <div className="border border-blue-600 rounded-lg absolute top-[28px] right-0 w-32 sm:w-28 text-center p-[2px] hover:border-green-600">
                                <button
                                  className="text-xs px-1 hover:text-green-700 text-blue-600 "
                                  onClick={(e) => {
                                    e.stopPropagation(); 
                                    setPrivateGroupPop(true);
                                    handleJoinGroup(user);
                                  }}
                                >
                                  Join Private Group
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      ))
                    : null}

                  {chatType === "public-group"
                    ? getAllPublicGroup &&
                      getAllPublicGroup.map((user, index) => (
                        <div
                          className={`flex px-5 py-[15px] hover:bg-[#E6EBF5] transition-all duration-300 cursor-pointer border-b-[1px] ${getAllPublicGroup.length-1 === index && ' mb-28'}`}
                          onClick={() => {
                            openChat(user?._id, user?.name, user?.members.length);
                            setIsGroup(true);
                          }}
                          key={user?._id}
                        >
                          <div className="relative self-center mr-3 border-[1px] border-gray-500 rounded-full p-1">
                            <p className="w-12 h-12 flex items-center justify-center rounded-full border border-gray-500 p-1 font-bold text-uppercase bg-slate-300 capitalize">
                              {user?.name?.charAt(0).toUpperCase()}
                            </p>
                            <span className="absolute w-2.5 h-2.5 bg-yellow-500 border-2 border-white rounded-full top-9 ltr:right-1 rtl:left-1 dark:border-zinc-600"></span>
                          </div>

                          <div className="flex-grow overflow-hidden">
                            <h5 className="mb-1 font-semibold text-black capitalize">
                              {user?.name}
                            </h5>
                            {/* <p className="mb-0 text-gray-500 truncate dark:text-gray-300 text-14">{user?.userHandle}</p> */}
                            <p className="mb-0 text-gray-500 dark:text-gray-300 text-[14px]">
                              {user?.lastMessage?.content}
                            </p>
                          </div>
                          <div className="text-gray-500 text-xs dark:text-gray-300 pt-1 relative">
                            {user?.lastMessage != null
                              ? GetDate(user?.lastMessage?.createdAt)
                              : GetDate(user?.updatedAt)}
                            {!isPublicGroupMember[index] && (
                              <div className="border border-blue-600 rounded-lg absolute top-[28px] right-0 w-32 sm:w-28 text-center p-[2px] hover:border-green-600">
                                <button
                                  className="text-xs px-1 hover:text-green-700 text-blue-600 "
                                  onClick={() => {
                                    handleJoinGroup(user);
                                  }}
                                >
                                  Join Public Group
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      ))
                    : null}
                </>
              )}
              </div>

              <div className="px-3 py-2 bg-gray-300 w-full fixed bottom-0">
                <div className="w-3/4 flex justify-between items-center m-auto ">
                  <div className={`px-2 py-1 rounded-lg  transition-all duration-300 ${ chatType === "chat" ? "bg-green-600 text-white border-green-500" : "bg-white border-slate-400" } cursor-pointer`} onClick={() => setChatType("chat")} >
                    <button>
                      <RiUser3Line className="text-3xl" />
                    </button>
                  </div>
                  <div className={`px-2 py-1 rounded-lg  transition-all duration-300 ${ chatType === "public-group" ? "bg-green-600 text-white border-green-500" : "bg-white border-slate-400" } cursor-pointer`} onClick={() => setChatType("public-group")} >
                    <button>
                      <GrGroup className="text-3xl" />
                    </button>
                  </div>
                  <div className={`px-2 py-1 rounded-lg  transition-all duration-300 ${ chatType === "private-group" ? "bg-green-600 text-white border-green-500" : "bg-white border-slate-400" } cursor-pointer`} onClick={() => setChatType("private-group")} >
                    <button>
                      <FaUserLock className="text-3xl" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex w-full justify-center items-center">
              <div className="bg-slate-200 p-6">
                <h1 className="text-4xl font-bold text-indigo-600 capitalize">
                  Hi {User?.userData?.firstname},
                </h1>
                <p className="text-2xl text-gray-700">Welcome back!</p>
                <p>Get ready to start chatting with your friends.</p>
              </div>
            </div>
          )}
        </>
      )}
      {privateGroupPop && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-[1000]">
        
          <div className="bg-white sm:px-8 px-4 py-12 rounded-lg shadow-lg sm:w-1/2 w-11/12 relative ">
            <button
              onClick={() => {setPrivateGroupPop(false);handleBackToChatList();setGroupPassword("");}}
              className="absolute top-3 right-3 px-3 py-1.5 bg-red-500 text-base sm:text-lg text-white rounded-lg hover:bg-red-600"
            >
              X
            </button>
            <div className="sm:w-1/2 m-auto pb-5 w-11/12">
            {loading ? (
              <div className="flex justify-center items-center min-h-[180px]">
                <CircularProgress />
              </div>
            ) :(
              <>
                <h2 className="mb-4 text-2xl sm:text-3xl font-semibold leading-tight text-[#005AE6] text-center">
                  Private Group
                </h2>
                <div>
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Enter Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={groupPassword}
                    onChange={(e) => setGroupPassword(e.target.value)}
                    className="bg-gray-50 border border-gray-300 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-4"
                  />
                </div>
                <button
                  className="flex items-center justify-center w-full text-white border border-[#005AE6] bg-[#005AE6] hover:bg-blue-600 transition-all duration-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  onClick={handleSubmitJoinGroup}
                >
                  {isVerify ? (
                    <>
                    <svg
                      width="24px"
                      height="24px"
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      stroke="#ffffff"
                      viewBox="0 0 100 100"
                      preserveAspectRatio="xMidYMid"
                    >
                      <circle cx="75" cy="50" fill="#ffffff" r="6.39718"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.875s" ></animate> </circle> <circle cx="67.678" cy="67.678" fill="#ffffff" r="4.8"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.75s" ></animate> </circle> <circle cx="50" cy="75" fill="#ffffff" r="4.8"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.625s" ></animate> </circle> <circle cx="32.322" cy="67.678" fill="#ffffff" r="4.8"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.5s" ></animate> </circle> <circle cx="25" cy="50" fill="#ffffff" r="4.8"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.375s" ></animate> </circle> <circle cx="32.322" cy="32.322" fill="#ffffff" r="4.80282" > <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.25s" ></animate> </circle> <circle cx="50" cy="25" fill="#ffffff" r="6.40282"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.125s" ></animate> </circle> <circle cx="67.678" cy="32.322" fill="#ffffff" r="7.99718" > <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="0s" ></animate> </circle>
                    </svg>{" "}
                    <span className="mt-[2px]">Loading</span>
                    </>
                  ) : 'Verify Password'}
                </button>
              </>
            )}
            </div>
          </div>
        </div>
      )}
      {isPrivateGroup && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-[1000]">
          <div className="bg-white sm:px-8 px-4 py-12 rounded-lg shadow-lg sm:w-1/2 w-11/12 relative ">
            <button
              onClick={() => {setIsPrivateGroup(false);handleBackToChatList()}}
              className="absolute top-3 right-3 px-3 py-1.5 bg-red-500 text-base sm:text-lg text-white rounded-lg hover:bg-red-600"
            >
              X
            </button>
            <h2 className="text-2xl sm:text-4xl font-semibold my-4 text-center text-[#005AE6]">
              Private Group
            </h2>
            <p className="text-gray-700 mb-6 text-center text-base">
              This is a private group. Please join first and then enter the
              password to access it.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

const Chats = (data) => {
  const { Calluser } = useContext(data?.data?.CallContext);
  const { AudioCalluser } = useContext(data?.data?.AudioCallContext);
  const { joinGroupCall } = useContext(data?.data?.GroupCallContext);
  const { groupCallInit } = useContext(data?.data?.NewGroupCallContext);
  const [groupmembershow, setGroupmembershow] = useState(false);

  const { id } = useParams();
  const token = getCookie("savage_token");
  const [messages, setMessages] = useState([]);
  const [loader, setLoader] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [imageUrl, setImageUrl] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [groupMemberPopup, setGroupMemberPopup] = useState(false);
  const [groupMemberData, setGroupMemberData] = useState([]);
  const [filteredGroupMembers, setFilteredGroupMembers] = useState([]);
  const [searchGroupMember, setSearchGroupMember] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [imagePop, setImagePop] = useState(false);
  const audioRef = useRef(null);
  const chatContainerRef = useRef(null);
  const { userData } = useUser();
  const handleEmojiPickerhideShow = (e) => {
    const emojiButton = document.querySelector(".emoji");
    if (emojiButton.contains(e.target)) {
      setShowEmojiPicker(!showEmojiPicker);
    } else {
      setShowEmojiPicker(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleEmojiPickerhideShow);
    return () => {
      document.removeEventListener("click", handleEmojiPickerhideShow);
    };
  }, []);
  const handleEmojiClick = (e, emojiObject) => {
    setInputValue((prevInputValue) => prevInputValue + e.emoji);
  };

  const senderName = data?.data?.senderName;
  const receiverName = data?.data?.receiverName;
  const senderId = data?.data?.senderId;
  const receiverId = data?.data?.receiverId;
  const selectedChatImage = data?.data?.selectedChatImage;
  const isGroup = data?.data?.isGroup;
  // const groupMemberCount = data?.data?.groupMemberCount;
  const [groupMemberCount, setGroupMemberCount] = useState(data?.data?.groupMemberCount);
  const groupMembers = data?.data?.groupMembers || [];
  const getIds = groupMembers.map((item) => item._id);
  getIds.push(data?.data?.creator);
  // console.log("groupMember", getIds);
  const isCheckPrivateGroupMember = data?.data?.isCheckPrivateGroupMember;

  useEffect(() => {
    setGroupMemberCount(data?.data?.groupMemberCount);
  }, [data?.data?.groupMemberCount]);

  useEffect(() => {
    const fetchMessages = async () => {
      setLoader(true);
      const payload = isGroup ? { isGroup: true,userId:userData?._id  } : {};
      const config = {
        url: `${apiurl}/message/${senderId}/${receiverId}`,
        method: "POST",
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        data: payload,
      };
      // console.log("url__",`${apiurl}/message/${senderId}/${receiverId}`);
      try {
        const response = await axios.request(config);
        const data = await response.data;
        if(data?.messages){
          setMessages(data.messages);
          setLoader(false);
        }
        handlegetGroupMember();
        setGroupmembershow(true);
      } catch (error) {
        setLoader(false);
        console.error("Error fetching messages:", error);
      }
    };
    fetchMessages();
  }, [receiverId]);

  useEffect(() => {
    socket.on("receive-message", handleReceivedMessage);
    return () => {
      socket.off("receive-message", handleReceivedMessage);
    };
  }, []);
  const handleReceivedMessage = (newMessage) => {
    setMessages((prevMessages) => [...prevMessages, newMessage]);
  };

  // console.log("messages__",messages);
  const createCallNotification = async (senderId, receiverId, callType) => {
    const data = {
      userId: {
        _id: senderId,
      },
      type: "AUDIO_VIDEO_CALL",
      message: callType === 'individual_audio_call' ? "individual_audio_call" : callType === 'individual_video_call' ? "individual_video_call" : callType === 'group_audio_call' ? 'group_audio_call' : 'group_video_call',
      author: {
        _id: receiverId,
      },
    };
    try {
      const response = await axios.post(
        `${apiurl}/notifications/createNotification`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
    } catch (error) {
      console.error("sent audio or video call Notification error", error);
    }
  };
  const createMessageNotification = async (senderId, receiverId, isGroup) => {
    const data = {
      userId: {
        _id: senderId,
      },
      type: "MESSAGE",
      message: isGroup ? "sent a message in the group" : "sent you a message",
      author: {
        _id: receiverId,
      },
    };
    try {
      const response = await axios.post(
        `${apiurl}/notifications/createNotification`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
    } catch (error) {
      console.error("sent message Notification error", error);
    }
  };
  const sendCallNotification = async (callType) => {
    const message = callType === 'individual_audio_call' ? "Audio call" : callType === 'individual_video_call' ? "Video call" : callType === 'group_audio_call' ? 'Audio call' : callType === 'group_video_call' ? 'Video call' : ''; 
    if (message) {
      try {
        const response = await axios.post(`${apiurl}/message/send`, {
          senderId,
          receiverId,
          content: message,
          isGroup,
          media: imageUrl,
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });
        setInputValue("");
        setImagePop(false);
        setImageUrl("");
        setMessages([...messages, response.data.message]);
        socket.emit("send-message", response.data.message);
        createCallNotification(senderId,receiverId,callType)
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    if (inputValue) {
      try {
        const response = await axios.post(`${apiurl}/message/send`, {
          senderId,
          receiverId,
          content: inputValue,
          isGroup,
          media: imageUrl,
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });
        setInputValue("");
        setImagePop(false);
        setImageUrl("");
        setMessages([...messages, response.data.message]);
        socket.emit("send-message", response.data.message);
        createMessageNotification(senderId,receiverId,isGroup);
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };
  useEffect(() => {
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }, [messages]);

  const lastMessageRef = useRef(null);

  const [isScrolledAway, setIsScrolledAway] = useState(false);

  // Scroll to the latest message
  const scrollToBottom = () => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Detect scrolling away from the bottom
  const handleScroll = () => {
    const container = chatContainerRef.current;
    if (container) {
      const isAtBottom =
        container.scrollHeight - container.scrollTop <= container.clientHeight + 20;
      setIsScrolledAway(!isAtBottom);
    }
  };

  useEffect(() => {
    const container = chatContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  // Automatically scroll to the bottom when a new message arrives
  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  
  const handleUpload = async (imageType, send_img) => {
    setImageLoading(true);
    setImagePop(false);
    setImageUrl(send_img);
    try {
      const updatedData = {
        [imageType]: send_img,
      };
      const response = await axios.put(
        `${apiurl}/user/update/${userData?._id}`,
        updatedData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response.status === 200) {
        setImageLoading(false);
        setImagePop(false);
        toast.success("Image Uploaded Successfully");
      } else {
        toast.error("Failed to Image Uploaded");
      }
    } catch (error) {
      console.error("Image Uploaded error", error);
    }
  };
  const ImageHandler = async (e) => {
    setImageLoading(true);
    try {
      const file = e.target.files[0];
      let myFile = file.name.split(".");
      let myFileType = myFile[myFile.length - 1];
      const s3 = new aws.S3({
        accessKeyId: awsKeyId,
        secretAccessKey: awsSecretKey,
      });
      const params = {
        Bucket: "savnewstorage",
        Key: `${uuidv4()}.${myFileType}`,
        Body: file,
        ContentType: file?.type,
      };
      let { Location } = await s3.upload(params).promise();
      setImageLoading(false);
      return Location;
    } catch (exception) {
      console.log(exception);
      return false;
    } finally {
    }
  };
  const setImage = async (e) => {
    const image = await ImageHandler(e);
    if (image) {
      await handleUpload("post_img", image);
    }
  };
  const isVideoExtension = (url) => {
    if (!url) return false;
    const videoExtensions = [".mp4", ".webm", ".ogg"];
    const videoExtensionchange = videoExtensions.toString();
    const extension = getExtension(url);
    const myurl = videoExtensionchange.includes(extension);
    return myurl;
  };
  const getExtension = (url) => {
    return url.split(".").pop().toLowerCase();
  };

  const [allUsers, setAllUsers] = useState([]);
  const [filteredMemberList, setFilteredMemberList] = useState([]);
  const [searchedUser, setSearchedUser] = useState("");
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [addingMembers, setAddingMembers] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [groupCreator, setGroupCreator] = useState(null);
  // const [originalMembers, setOriginalMembers] = useState([]);

  const handlegetGroupMember = async () => {
    setAddingMembers(false);
    setGroupMemberPopup(true);
    try {
      const response = await axios.get(
        `${apiurl}/message/get-group-members/${receiverId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response.data.success === true) {
        setGroupCreator(response?.data?.group);
        const members = response?.data?.members;
        const sortedMembers = members.sort((a,b)=> a.name.localeCompare(b.name));
        setGroupMemberData(sortedMembers);
        setFilteredGroupMembers(sortedMembers);
        // setOriginalMembers(sortedMembers.map((member) => member._id));
        setSelectedMembers(sortedMembers.map((member) => member._id));
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      console.error("get group member error", error);
    }
  };
  const [callLoading,setCallLoading]=useState(true)
  const handlecallGroupMember = async (callkind) => {
    setCallLoading(false);
    try {
      const response = await axios.get(
        `${apiurl}/message/get-group-members/${receiverId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response.data.success === true) {
        setCallLoading(true);
        const members = response?.data?.members;
        groupCallInit(
          {
            members,
            room: receiverId,
            name: receiverName,
          },
          callkind=="audio"?false:true // Adjust this value based on your needs
        );


        //return members;
      } else {
        console.error(response.data.message);
        return [];
      }
    } catch (error) {
      console.error("get group member error", error);
    }
  };

  const getAllUsers = async () => {
    try {
      const response = await axios.get(`${apiurl}/user/all`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      if(response?.data?.users){
        const users = response.data.users;
        const sortedUsers = users.sort((a, b) => a.name.localeCompare(b.name));
        const filteredUsers = sortedUsers.filter( (member) => member._id !== userData?._id );
        setAllUsers(filteredUsers);
        if (addingMembers) {
          setFilteredMemberList(response.data.users); 
        }
      }
    } catch (error) {
      console.error("get all users error", error);
    }
  };

  useEffect(() => {
    const loadData = () => {
      if (addingMembers) {
        setFilteredMemberList([...allUsers].sort((a, b) => a.name.localeCompare(b.name)));
      } else {
        setFilteredGroupMembers([...groupMemberData].sort((a, b) => a.name.localeCompare(b.name)));
      }
      setIsLoading(false);
    };
  
    const timer = setTimeout(loadData, 300); 
    return () => clearTimeout(timer);
  }, [addingMembers, allUsers, groupMemberData]);
  

  const handleSearchChange = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchedUser(searchValue);

    if (addingMembers) {
      const sortedFilteredList = allUsers
        .filter((user) => user.name.toLowerCase().includes(searchValue))
        .sort((a, b) => a.name.localeCompare(b.name)); 
      setFilteredMemberList(sortedFilteredList);
    } else {
      const sortedFilteredList = groupMemberData
        .filter((user) => user.name.toLowerCase().includes(searchValue))
        .sort((a, b) => a.name.localeCompare(b.name)); 
      setFilteredGroupMembers(sortedFilteredList);
    }
  };

  const handleCheckboxChange = (userId) => {
    const isChecked = selectedMembers.includes(userId);
    if (isChecked) {
      setSelectedMembers(selectedMembers.filter((id) => id !== userId));
    } else {
      setSelectedMembers([...selectedMembers, userId]);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const updateGroupMembers = (members) => {
    // const addedMembers = selectedMembers.filter(
    //   (id) => !originalMembers.includes(id)
    // ); 
    // const removedMembers = originalMembers.filter(
    //   (id) => !selectedMembers.includes(id)
    // ); 
  
    // console.log("__Added Members:", addedMembers);
    // console.log("__Removed Members:", removedMembers);
    // console.log("__Count Added:", addedMembers.length);
    // console.log("__Count Removed:", removedMembers.length);

    setIsLoader(true);
    if (receiverId) {
      const data = {
        groupId: receiverId,
        members: members,
      };
      const config = {
        method: "post",
        url: `${apiurl}/message/updategroup`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          if(response?.data?.group){
            setIsLoader(false);
            setAddingMembers((prev) => !prev);
            handlegetGroupMember();
            setSearchedUser("");
            setGroupMemberCount(response?.data?.group?.members?.length);
          }
        })
        .catch((error) => {
          setIsLoader(false);
          console.error(error);
        });
    }
  };

  const handleToggleAddingMembers = () => {
    setIsLoading(true); 
    setAddingMembers((prev) => !prev);
    setSearchedUser("");
  };
  
  const handleCloseGroupMembers = () => {
    setGroupMemberPopup(false);
    setAddingMembers(false);
    setSearchedUser("");
  };

  // useEffect(() => {
  //   socket.on("receive-message", (newMessage) => {
  //     handleReceivedMessage(newMessage);
  //     // console.log("newMessage", newMessage);
  //   });
  //   socket.on("disconnect", () => {
  //     console.log("Disconnected from the server");
  //   });
  // });
  const createRoom = () => {
    const roomId = Math.floor(10000000 + Math.random() * 90000000);
    // console.log("roomId", roomId);
    return roomId;
  };

  return (
    <>
    <div
      className={`${
        isCheckPrivateGroupMember === undefined
          ? "flex flex-col items-center justify-center sm:justify-between h-full w-full"
          : isCheckPrivateGroupMember === true
          ? "flex flex-col items-center justify-center sm:justify-between h-full w-full"
          : "flex flex-col items-center justify-center sm:justify-between h-full w-full blur-[15px] filter"
      }`}
    >
      <div className="fixed sm:relative sm:z-0 z-[999] top-0  flex flex-row py-3 px-2 items-center border-b-2 bg-white w-full cursor-pointer">
        <button onClick={data?.data?.handleBackToChatList} className="ddd">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>

        {selectedChatImage && selectedChatImage.length > 0 ? (
          <div className="mx-1 border-[1px] border-gray-500 rounded-full p-1">
            <img
              src={selectedChatImage}
              className="object-cover sm:h-11 sm:w-14 w-20 h-11 rounded-full border-[1px] border-gray-500"
              alt="profile"
            />
          </div>
        ) : (
          <div className="mr-3 border-[1px] border-gray-500 rounded-full p-1">
            <p className="w-12 h-12 flex items-center justify-center rounded-full border border-gray-500 p-1 font-bold text-uppercase bg-slate-300">
              {receiverName?.charAt(0).toUpperCase()}
            </p>
          </div>
        )}
        <div
          className="w-full"
          onClick={() =>
            isGroup
              ? (handlegetGroupMember(), setGroupmembershow(false))
              : setGroupmembershow(true)
          }
        >
          <h5 className="text-lg font-semibold sm:w-full w-40 overflow-hidden text-ellipsis whitespace-nowrap capitalize">
            {receiverName}
          </h5>
          <p className="text-gray-500 text-xs sm:w-full w-36 overflow-hidden text-ellipsis whitespace-nowrap capitalize">
            {`Hi ${receiverName}, Welcome `}
            {isGroup && (
              <span className="text-gray-700 text-xs font-semibold">
                {groupMemberCount > 0 ? `${groupMemberCount} ${groupMemberCount > 1 ? 'Members' : 'Member'}` : ""}
              </span>
            )}
          </p>
        </div>

        <div className="flex flex-col items-center justify-between w-32">
          <hr />
          <div>
            <audio ref={audioRef} autoPlay />
            <div className="flex items-center justify-center gap-2">
              {!isGroup ? (
                <>
                  <button
                    className="bg-green-500 text-white px-3 py-2 rounded-full 1"
                    // onClick={() => callUser(receiverId, userData?._id)}
                    onClick={() => {
                      AudioCalluser(receiverId);
                      sendCallNotification('individual_audio_call')
                    }}
                  >
                    <svg
                      width="20px"
                      height="20px"
                      viewBox="0 0 15 15"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      fill="#000000"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth="0" />

                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />

                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <defs> </defs>{" "}
                        <g
                          id="Page-1"
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          {" "}
                          <g
                            id="Dribbble-Light-Preview"
                            transform="translate(-103.000000, -7321.000000)"
                            fill="#FFF"
                          >
                            {" "}
                            <g
                              id="icons"
                              transform="translate(56.000000, 160.000000)"
                            >
                              {" "}
                              <path
                                d="M61.7302966,7173.99596 C61.2672966,7175.40296 59.4532966,7176.10496 58.1572966,7175.98796 C56.3872966,7175.82796 54.4612966,7174.88896 52.9992966,7173.85496 C50.8502966,7172.33496 48.8372966,7169.98396 47.6642966,7167.48896 C46.8352966,7165.72596 46.6492966,7163.55796 47.8822966,7161.95096 C48.3382966,7161.35696 48.8312966,7161.03996 49.5722966,7161.00296 C50.6002966,7160.95296 50.7442966,7161.54096 51.0972966,7162.45696 C51.3602966,7163.14196 51.7112966,7163.84096 51.9072966,7164.55096 C52.2742966,7165.87596 50.9912966,7165.93096 50.8292966,7167.01396 C50.7282966,7167.69696 51.5562966,7168.61296 51.9302966,7169.09996 C52.6632966,7170.05396 53.5442966,7170.87696 54.5382966,7171.50296 C55.1072966,7171.86196 56.0262966,7172.50896 56.6782966,7172.15196 C57.6822966,7171.60196 57.5872966,7169.90896 58.9912966,7170.48196 C59.7182966,7170.77796 60.4222966,7171.20496 61.1162966,7171.57896 C62.1892966,7172.15596 62.1392966,7172.75396 61.7302966,7173.99596 C61.4242966,7174.92396 62.0362966,7173.06796 61.7302966,7173.99596"
                                id="call-[0f7511]"
                              >
                                {" "}
                              </path>{" "}
                            </g>{" "}
                          </g>{" "}
                        </g>{" "}
                      </g>
                    </svg>
                  </button>
                  <button
                    className="bg-green-500 text-white px-3 py-2 rounded-full 2"
                    // onClick={() => callUser(receiverId, userData?._id)}
                    onClick={() => {
                      groupCallInit(
                        {
                          members: [
                            {
                              _id: receiverId,
                              name: receiverName,
                            },
                          ],
                          room: createRoom(),
                          name: userData?.name,
                        },
                        true
                      );
                      sendCallNotification('individual_video_call');
                    }}
                  >
                    <svg
                      width="20px"
                      height="20px"
                      viewBox="0 0 1024 1024"
                      className="icon"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#000000"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth="0" />

                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />

                      <g id="SVGRepo_iconCarrier">
                        <path
                          d="M170.666667 256h469.333333c46.933333 0 85.333333 38.4 85.333333 85.333333v341.333334c0 46.933333-38.4 85.333333-85.333333 85.333333H170.666667c-46.933333 0-85.333333-38.4-85.333334-85.333333V341.333333c0-46.933333 38.4-85.333333 85.333334-85.333333z"
                          fill="#fff"
                        />

                        <path
                          d="M938.666667 746.666667l-213.333334-128V405.333333l213.333334-128z"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                  </button>
                </>
              ) : (
                callLoading?
                <>
                
                  <button
                    className="bg-green-500 text-white px-3 py-2 rounded-full 1"
                    // onClick={() => callUser(receiverId, userData?._id)}
                    onClick={()=>{
                      handlecallGroupMember("audio"); sendCallNotification('group_audio_call')}}
                  >
                    <svg
                      width="20px"
                      height="20px"
                      viewBox="0 0 15 15"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      fill="#000000"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth="0" />

                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />

                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <defs> </defs>{" "}
                        <g
                          id="Page-1"
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          {" "}
                          <g
                            id="Dribbble-Light-Preview"
                            transform="translate(-103.000000, -7321.000000)"
                            fill="#FFF"
                          >
                            {" "}
                            <g
                              id="icons"
                              transform="translate(56.000000, 160.000000)"
                            >
                              {" "}
                              <path
                                d="M61.7302966,7173.99596 C61.2672966,7175.40296 59.4532966,7176.10496 58.1572966,7175.98796 C56.3872966,7175.82796 54.4612966,7174.88896 52.9992966,7173.85496 C50.8502966,7172.33496 48.8372966,7169.98396 47.6642966,7167.48896 C46.8352966,7165.72596 46.6492966,7163.55796 47.8822966,7161.95096 C48.3382966,7161.35696 48.8312966,7161.03996 49.5722966,7161.00296 C50.6002966,7160.95296 50.7442966,7161.54096 51.0972966,7162.45696 C51.3602966,7163.14196 51.7112966,7163.84096 51.9072966,7164.55096 C52.2742966,7165.87596 50.9912966,7165.93096 50.8292966,7167.01396 C50.7282966,7167.69696 51.5562966,7168.61296 51.9302966,7169.09996 C52.6632966,7170.05396 53.5442966,7170.87696 54.5382966,7171.50296 C55.1072966,7171.86196 56.0262966,7172.50896 56.6782966,7172.15196 C57.6822966,7171.60196 57.5872966,7169.90896 58.9912966,7170.48196 C59.7182966,7170.77796 60.4222966,7171.20496 61.1162966,7171.57896 C62.1892966,7172.15596 62.1392966,7172.75396 61.7302966,7173.99596 C61.4242966,7174.92396 62.0362966,7173.06796 61.7302966,7173.99596"
                                id="call-[0f7511]"
                              >
                                {" "}
                              </path>{" "}
                            </g>{" "}
                          </g>{" "}
                        </g>{" "}
                      </g>
                    </svg>
                  </button>
                  <button
                    className="bg-green-500 text-white px-3 py-2 rounded-full 2"
                    // onClick={() => callUser(receiverId, userData?._id)}
                    onClick={()=>{
                      handlecallGroupMember("video"); sendCallNotification('group_video_call')}}
                  >
                    <svg
                      width="20px"
                      height="20px"
                      viewBox="0 0 1024 1024"
                      className="icon"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#000000"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth="0" />

                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />

                      <g id="SVGRepo_iconCarrier">
                        <path
                          d="M170.666667 256h469.333333c46.933333 0 85.333333 38.4 85.333333 85.333333v341.333334c0 46.933333-38.4 85.333333-85.333333 85.333333H170.666667c-46.933333 0-85.333333-38.4-85.333334-85.333333V341.333333c0-46.933333 38.4-85.333333 85.333334-85.333333z"
                          fill="#fff"
                        />

                        <path
                          d="M938.666667 746.666667l-213.333334-128V405.333333l213.333334-128z"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                  </button>
                </>:<p className="text-[#ff0000] w-[300px] flex justify-start fixed overflow-hidden">Please wait call is connecting....</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        ref={chatContainerRef}
        className={`flex flex-col gap-2 w-full sm:overflow-hidden ${loader ? 'm-auto items-center justify-center' : 'sm:mt-0'}`}
        // style={{ maxHeight: "650px" }}
      >
      {loader ? (
        <div className="flex justify-center items-center">
          <CircularProgress />
        </div>
      ) : 
        <div className="chat-container overflow-y-scroll overflow-x-hidden my-16 sm:my-auto">
          {messages.length > 0 ? (
            messages.map((message, index) => {
              const sanitizedContent = DOMPurify.sanitize(message.content);
              const isSender = message.sender === senderId;
              const messageClasses = `relative max-w-72 min-w-20 rounded-md ${
                isSender
                  ? "self-end bg-gray-200 mr-3 text-black"
                  : "self-start bg-blue-500 text-left ml-3 text-white"
              }`;
              const triangleClasses = `absolute ${
                isSender
                  ? "right-0 bottom-[-10px] mx-auto h-0 w-0 border-r-31p border-b-35p border-t-35p border-solid border-r-transparent border-l-transparent border-b-[#000] rotate-90 border-gray-200"
                  : "left-0 bottom-[-10px] mx-auto h-0 w-0 border-r-31p border-b-35p border-t-35p border-solid border-r-transparent border-l-transparent border-b-[#000] border-t-[17px] border-r-[17px] transform rotate-0 border-blue-500"
              } border-solid border-t-[17px] border-r-[17px] h-0 w-0 transform`;

              return (
                <div
                  key={message?._id}
                  ref={index === messages.length - 1 ? lastMessageRef : null}
                  className={`flex my-6 ${
                    isSender ? "justify-end" : "justify-end flex-row-reverse"
                  }`}
                >
                  <div className={messageClasses}>
                    <div className="rounded-xl mb-5">
                      {message.media && (
                        <div className="rounded-xl m-4">
                          {isVideoExtension(message.media) ? (
                            <video
                              className="w-auto h-auto rounded-lg relative m-auto"
                              controls
                              autoPlay
                              loop
                              muted
                            >
                              <source
                                src={message.media}
                                type="video/mp4"
                                alt="video"
                              />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <img
                              src={message.media}
                              alt="selected"
                              className="w-auto h-auto rounded-lg relative m-auto"
                            />
                          )}
                          <div
                            className="w-3/4"
                            dangerouslySetInnerHTML={{
                              __html: sanitizedContent,
                            }}
                          />
                        </div>
                      )}
                      {!message.media && (
                        <div
                          className="w-3/4 m-4"
                          dangerouslySetInnerHTML={{ __html: sanitizedContent }}
                        />
                        //send_message
                      )}
                    </div>
                    <hr />
                    <p
                      className={`text-xs ${
                        isSender
                          ? "text-left text-[#74788D] ml-4 mb-2"
                          : "text-right text-[#B9B4F7] mr-2 mb-2"
                      }`}
                    >
                      <span className="align-middle">
                        {GetTimeforchat(message.createdAt)}
                      </span>
                    </p>
                    <div className={triangleClasses}></div>
                  </div>
                  <div className="bg-white flex items-center justify-center rounded-full m-1 min-h-10 min-w-10">
                    {isSender ? (
                        <p className="w-10 h-10 flex items-center justify-center rounded-full mr-2 border p-1 font-semibold text-uppercase bg-blue-400 border-gray-500 sm:text-xl text-lg">
                          {message.senderFirstName?.charAt(0).toUpperCase() ||
                            userData.firstname?.charAt(0).toUpperCase()}
                        </p>
                    ) : (
                        <Link to={`/profile/${message?.sender}`}>
                          <p className="w-10 h-10 flex items-center justify-center rounded-full mr-2 border p-1 font-semibold text-uppercase bg-green-400 border-gray-500 sm:text-xl text-lg">
                            {message.receiverFirstName?.charAt(0).toUpperCase() || receiverName?.charAt(0).toUpperCase()}
                          </p>
                        </Link>
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <p className="flex justify-center font-bold text-[20px]">
              You have no previous chat
            </p>
          )}
        </div>
        }
        {isScrolledAway && (
        <button
          onClick={scrollToBottom}
          className="fixed bottom-10 right-10 p-3 bg-blue-500 text-white rounded-full shadow-lg hover:bg-blue-600"
        >
          <KeyboardArrowDownIcon size={30} />
        </button>
      )}
      </div>
      {/* Input for sending messages */}
      <div className="sm:relative fixed bottom-0 flex items-center w-full border-t-[1px] bg-[#fff]">
        <div>
          {imageUrl && imageUrl.length > 0 && (
            <div
              onClick={() => setImagePop(false)}
              className="relative m-2 w-11/12 ml-6 mb-6"
            >
              {isVideoExtension(imageUrl) === true ? (
                <video
                  className="w-full h-[100px] rounded-lg relative"
                  controls
                  autoPlay
                  loop
                  muted
                >
                  <source
                    src="https://savageseller.s3.amazonaws.com/380f8d1f-4e9e-4a33-9dbc-f1a7b6937ec2.mp4"
                    type="video/mp4"
                    alt="video"
                  />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  src={imageUrl}
                  alt="selected-image"
                  className="w-full h-[100px] rounded-lg relative"
                />
              )}
              <span
                onClick={() => setImageUrl("")}
                className="absolute top-2 right-2 text-lg z-20 text-black border border-white rounded-full px-2  cursor-pointer bg-white"
              >
                X
              </span>
            </div>
          )}
        </div>

        <form
          className="w-full flex sm:m-6 mx-3 my-4 mb-6 relative"
          onSubmit={(e) => sendMessage(e)}
        >
          <div className="button-container flex items-center absolute left-[5px] top-[12px]">
            <div className="emoji text-yellow-600 text-xl">
              <BsEmojiSmileFill onClick={handleEmojiPickerhideShow} />
              {showEmojiPicker && <Picker onEmojiClick={handleEmojiClick} />}
            </div>
          </div>
          <input
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            className="flex-grow py-2 px-8 rounded-md border border-gray-400 w-11/12"
            placeholder="Type your message . . ."
          />
          <label
            htmlFor="fileInput"
            className={`${
              imageUrl && imageUrl.length > 0 ? "bg-green-500" : "bg-blue-500"
            } text-white cursor-pointer rounded mb-0 flex items-center px-2 py-[2px] ml-2`}
          >
            <MdAddPhotoAlternate className="text-4xl" />
            <input
              id="fileInput"
              type="file"
              className="hidden"
              name="send_img"
              onChange={(e) => {
                setImage(e);
              }}
            />
          </label>
          <button
            className={`${
              imageLoading === true ? "bg-green-500" : "bg-blue-500"
            } hover:bg-blue-700 text-white font-bold py-2 sm:px-4 px-3 rounded ml-2 sm:text-base text-sm`}
            type="button"
            onClick={sendMessage}
            disabled={imageLoading}
          >
            {imageLoading === true ? "Uploading . . ." : "Send"}
          </button>

          {groupMemberPopup === true && !groupmembershow ? (
            <>
              <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75 z-30">
                <div className="bg-white sm:p-6 p-2 rounded-xl shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] text-center flex justify-start items-center flex-col sm:w-2/5 w-11/12 slideBottom1">
                  <div className="flex justify-between items-center w-full">
                    <p className="text-lg">All Group Members</p>
                    <div className="flex space-x-2">
                      {(groupCreator && groupCreator?.creator === senderId && groupCreator?.creator === userData?._id ) && (
                        <button
                          className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                          onClick={handleToggleAddingMembers}
                        >
                          {addingMembers ? "View Members" : "Manage Members"}
                        </button>
                      )}
                      <button
                        className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
                        onClick={handleCloseGroupMembers}
                      >
                        X
                      </button>
                    </div>
                  </div>

                  <div className="w-full px-2 pt-2">
                    <div className="my-1 p-1 flex bg-[#fff] border border-gray-400 w-full rounded-lg">
                      <input
                        type="text"
                        className="px-2 py-2 w-full focus:outline-none"
                        placeholder="Search Users . . ."
                        value={searchedUser}
                        onChange={handleSearchChange}
                      />
                    </div>

                    <div className={`sm:mt-4 mt-2 w-full bg-gray-100 border border-gray-300 rounded-lg shadow-md overflow-y-scroll h-64 ${isLoading ? 'flex flex-col items-center justify-center' : '' }`}>
                      {addingMembers ? (
                        <>
                        {isLoading ? (
                          <div className="flex justify-center items-center loader1">
                            <CircularProgress />
                          </div> ) : (
                            <>
                              {filteredMemberList.map((user, index) => (
                                <label
                                  className="flex justify-left px-5 py-[12px] hover:bg-[#E6EBF5] transition-all duration-300 cursor-pointer items-center border-b-2 mb-0"
                                  key={user?._id}
                                >
                                  <div className="relative self-center mr-3 border-[1px] border-gray-500 rounded-full p-1">
                                    <img
                                      className="w-12 h-12 flex items-center justify-center rounded-full border border-gray-500 font-bold text-uppercase bg-slate-300"
                                      src={user.hero_img || `/profile.png`}
                                      alt="profile-image"
                                    />
                                    <span className="absolute w-2.5 h-2.5 bg-yellow-500 border-2 border-white rounded-full top-12 ltr:right-1 rtl:left-1 dark:border-zinc-600"></span>
                                  </div>
                                  <div className="flex justify-between w-3/4">
                                    <div className="text-left">
                                      <h5 className="font-semibold text-black">
                                        {userData?._id === user?._id ? `You` : user?.name}
                                      </h5>
                                      <p className="mb-0 text-gray-500 dark:text-gray-300 text-[14px]">
                                        {user?.designation}
                                      </p>
                                    </div>
                                    <div className="text-gray-500 text-xs dark:text-gray-300 pt-1 pr-1">
                                      {GetTimeforchat(user?.createdAt)}
                                    </div>
                                  </div>
                                  <input
                                    type="checkbox"
                                    className="ml-2 pt-1"
                                    checked={
                                      selectedMembers.includes(user._id) ||
                                      (!addingMembers &&
                                        groupMemberData.some(
                                          (member) => member._id === user._id
                                        ))
                                    }
                                    onChange={() => handleCheckboxChange(user._id)}
                                  />
                                </label>
                              ))}
                            </>
                          )}
                        </>
                      ):(
                        <>
                        {isLoading ? (
                          <div className="flex justify-center items-center loader1">
                            <CircularProgress />
                          </div> ) : (
                            <>
                              {filteredGroupMembers.map((user, index) => (
                                <Link to={`/profile/${user?._id}`} key={user?._id}>
                                  <label
                                    className="flex justify-left px-5 py-[12px] hover:bg-[#E6EBF5] transition-all duration-300 cursor-pointer items-center border-b-2 mb-0"
                                    onClick={() => user?._id}
                                    key={index}
                                  >
                                    <div className="relative self-center mr-3 border-[1px] border-gray-500 rounded-full p-1">
                                      <img
                                        className="w-12 h-12 flex items-center justify-center rounded-full border border-gray-500 font-bold text-uppercase bg-slate-300"
                                        src={user?.hero_img || `/profile.png`}
                                        alt="profile-image"
                                      />
                                      <span className={`absolute w-2.5 h-2.5 ${userData?._id === user?._id ? `bg-green-500` : `bg-yellow-500`} border-2 border-white rounded-full top-12 ltr:right-1 rtl:left-1 dark:border-zinc-600`}></span>
                                    </div>
                                    <div className="flex justify-between w-3/4">
                                      <div className="text-left">
                                        <h5 className="font-semibold text-black">
                                          {userData?._id === user?._id ? `You` : user?.name}
                                          {groupCreator?.creator === user?._id ? ' (Admin)' : '' }
                                        </h5>
                                        <p className="mb-0 text-gray-500 dark:text-gray-300 text-[14px]">
                                          {user?.designation}
                                        </p>
                                      </div>
                                      <div className="text-gray-500 text-xs dark:text-gray-300 pt-1">
                                        {GetTimeforchat(user?.createdAt)}
                                      </div>
                                    </div>
                                  </label>
                                </Link>
                              ))}
                            </>
                          )}
                        </>
                      )}
                    </div>
                    {addingMembers && 
                      <div className="w-full px-2 pt-4">
                        <button
                          className="px-8 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                          onClick={()=>updateGroupMembers(selectedMembers)}
                        >
                          {isLoader ? 'Loading...' : 'Update'}
                        </button>
                      </div>
                    }
                    
                  </div>
                </div>
              </div>
            </>
          ) : null}

        </form>
      </div>
    </div>
    </>
  );
};
